import React from "react";

//COMPONENTS
import Product from "../components/Products/product";
import AddProducts from "../components/Products/addProduct";
import SideBarAdmin from "../components/sidenavbar";

export default function ManageProducts() {
  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-4 m-2 sm:m-4 w-full h-screen overflow-y-auto">
        <h1 className="text-white font-bold rounded-lg bg-bikeexplogray-light p-2 flex flex-row justify-between h-12 items-center text-xs md:text-base">
          Gestion des produits
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5 mr-3 lg:w-6 lg:h-6 lg:mr-5 text-salmon"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
            />
          </svg>
        </h1>
        <Product />
        <div className="text-center">
          <AddProducts />
        </div>
      </div>
    </div>
  );
}
