import React, { useEffect, useState } from "react";
import { PencilIcon } from "@heroicons/react/solid";
import axios from "axios";
import { apiUrl } from "../../utils/urls.js";

export default function UpdateFAQ(props, { data }) {
  const [formData, setFormData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [faq, setFaq] = useState("");

  async function updateFaq(e) {
    e.preventDefault();
    setFaq({ ...faq, formData });

    data = formData;

    axios
      .put(`${apiUrl}/api/faqs/${props.id}`, data)
      .then((response) => {
        setFaq(response.data);
        setConfirm(true);

        setTimeout(function () {
          // after 2 seconds
          setIsOpen(false);
          window.location.reload();
        }, 2000);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function open() {
    setIsOpen(true);
  }
  async function close() {
    setIsOpen(false);
  }
  return (
    <>
      <button
        className="mt-2 bg-salmon hover:bg-white rounded-full text-white hover:text-salmon p-2"
        onClick={open}
      >
        <PencilIcon className="w-4 h-4"></PencilIcon>
      </button>

      {isOpen && (
        <div
          className={`absolute w-full h-full top-0 left-0 z-50 flex justify-center items-center ${
            !isOpen ? "hidden" : ""
          }`}
        >
          <div className="bg-black opacity-50 absolute w-full h-full top-0 left-0"></div>
          <form
            className="flex flex-col gap-2 bg-bikeexplogray-light w-full md:w-1/2 p-5 rounded-lg shadow-md text-salmon prose relative"
            onSubmit={updateFaq}
          >
            <h2 className="text-center p-1">Modifier un élément dans la FAQ</h2>
            {confirm && (
              <div
                id="confirmmessage"
                className="rounded-full bg-green-400 text-white text-center w-full text-xs md:text-base"
              >
                L'élément a bien été modifié
              </div>
            )}
            <div className="flex flex-col gap-2">
              <input
                type="text"
                placeholder="Question"
                className="placeholder-bikeexplogray-light text-white rounded-lg w-full border px-2 bg-bikeexplogray-dark border-salmon"
                onChange={(e) =>
                  setFormData({ ...formData, question: e.target.value })
                }
              />
              <textarea
                onChange={(e) =>
                  setFormData({ ...formData, answer: e.target.value })
                }
                className="placeholder-bikeexplogray-light text-white rounded-lg w-full border px-2 bg-bikeexplogray-dark border-salmon"
                placeholder="Réponse"
              ></textarea>
            </div>
            <div className="flex flex-row gap-1 justify-center">
              <button
                type="submit"
                className="bg-salmon hover:bg-white text-white hover:text-salmon p-2 rounded-lg"
              >
                Ajouter
              </button>
              <button
                className="border-salmon border-2 hover:border-white text-white hover:text-salmon p-2 rounded-lg"
                onClick={close}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
