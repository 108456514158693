import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import SideBarAdmin from "../../components/sidenavbar";
import axios from "axios";
import ReactPaginate from "react-paginate";
import SearchBar from "../../components/searchBar";
import { apiUrl } from "../../utils/urls.js";

export default function PoiAdmin() {
  const [pois, setPois] = useState();
  const queryKey = ["pois"];
  const { isLoading, data } = useQuery(queryKey, () => loadPois());
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    async (id) => {
      let url = `${apiUrl}/api/pointsOfInterest/${id}`;
      await axios
        .delete(url)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  // PAGINATION
  const PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * PER_PAGE;
  const totalPoi = pois?.length;
  const pageCount = Math.ceil(totalPoi / PER_PAGE);

  const currentPageData = pois?.slice(offset, offset + PER_PAGE).map((poi) => {
    let dateStr = poi.updatedAt; //returned from mysql timestamp/datetime field
    let a = dateStr.split("-");
    let d = a[2].split(":");
    let e = d[0].split("T");
    let date = e[0] + "/" + a[1] + "/" + a[0];

    return (
      <tr
        className="text-white text-xs sm:text-sm lg:text-base even:bg-bikeexplogray-dark"
        key={poi.id}
      >
        <td className="text-center lg:my-5 lg:mx-2 lg:h-12">
          <div className="text-start">{poi.title}</div>
        </td>
        <td className="text-center h-14">{poi.address}</td>
        <td className="text-center h-14">{date}</td>
        <td className="flex items-center h-14" id="button">
          <a
            href={`/editPoi/${poi.id}`}
            className="text-xs lg:text-sm lg:font-bold flex items-center justify-center rounded-full bg-salmon w-16 h-8 lg:w-28 lg:h-9 border-2 border-salmon hover:bg-bikeexplogray-dark hover:border-salmon hover:border-1"
          >
            <svg
              className="lg:w-6 lg:h-6 w-4 h-4 mx-1 hidden lg:block"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              ></path>
            </svg>
            Modifier
          </a>
          <button onClick={(e) => mutate(poi.id)}>
            <svg
              id="deleteBtn"
              className="w-4 h-4 ml-2 lg:w-6 lg:h-6 lg:ml-5"
              fill="none"
              stroke="salmon"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              ></path>
            </svg>
          </button>
        </td>
      </tr>
    );
  });

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  function loadPois() {
    axios
      .get(`${apiUrl}/api/pointsOfInterest/`)
      .then((res) => {
        setPois(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="bg-bikeexplogray-dark flex flex-row gap-2 lg:gap-4 w-full h-full overflow-y-auto">
      <SideBarAdmin />
      <div className="flex flex-col gap-4 m-2 sm:m-4 w-full">
        <div className="flex justify-between w-full items-center bg-bikeexplogray-light text-white px-1 sm:px-2 rounded-lg h-12">
          <h1 className="text-white text-xs md:text-base font-bold self-center flex flex-row gap-2 lg:w-1/2 items-center">
            <svg
              className="w-5 sm:w-6 h-5 sm:h-6"
              viewBox="0 0 14 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 2V7C9 8.12 9.37 9.16 10 10H4C4.65 9.14 5 8.1 5 7V2H9ZM12 0H2C1.45 0 1 0.45 1 1C1 1.55 1.45 2 2 2H3V7C3 8.66 1.66 10 0 10V12H5.97V19L6.97 20L7.97 19V12H14V10C12.34 10 11 8.66 11 7V2H12C12.55 2 13 1.55 13 1C13 0.45 12.55 0 12 0Z"
                fill="salmon"
              />
            </svg>
            Liste des points d'intérêts
          </h1>
          <SearchBar
            placeholder="Rechercher un point d'intérêt"
            data={pois}
            link={"editPoi/"}
          />
        </div>
        <a
          href="/createPoi"
          className="flex flex-row text-xs justify-center self-end lg:text-sm lg:font-bold w-1/2 lg:w-1/6 p-1 rounded-full bg-salmon text-white border-2 border-salmon hover:bg-white hover:border-salmon hover:border-2 hover:text-salmon"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            ></path>
          </svg>
          <button>AJOUTER UN POINT</button>
        </a>
        <div>
          <div className="bg-salmon text-white text-center rounded-t-lg p-1 lg:p-2 flex items-center justify-center text-sm lg:text-base lg:font-semibold">
            Points d'intérêts
            {isLoading && (
              <div
                className="spinner-border animate-spin inline-block w-5 ml-4 h-5 border-3 rounded-full text-white"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </div>
          {isSuccess && (
            <div className="bg-green-500 text-white text-center flex items-center justify-center text-xs lg:font-bold lg:text-sm">
              Point d'intérêt supprimé !
            </div>
          )}
          <table className="bg-bikeexplogray-light w-full">
            <thead>
              <tr className="text-salmon text-xs sm:text-sm lg:text-base bg-bikeexplogray-dark h-8 lg:h-10">
                <th>Nom</th>
                <th>Adresse</th>
                <th>Dernières modifications</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{currentPageData}</tbody>
          </table>
          <div className="bg-bikeexplogray-light rounded-b-lg lg:h-10 h-8 flex items-center justify-center text-xs sm:text-sm lg:text-base lg:font-bold">
            <ReactPaginate
              className="flex  items-center h-full"
              previousLabel={
                <svg
                  className="lg:w-6 lg:h-6 w-4 h-4"
                  fill="none"
                  stroke="salmon"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  ></path>
                </svg>
              }
              nextLabel={
                <svg
                  className="lg:w-6 lg:h-6 w-4 h-4"
                  fill="none"
                  stroke="salmon"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  ></path>
                </svg>
              }
              pageCount={pageCount}
              onPageChange={handlePageClick}
              activeClassName={"bg-bikeexplogray-light"}
              pageClassName={
                "mx-5 bg-bikeexplogray-dark h-full w-12 flex items-center justify-center text-salmon"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
