import React from "react";
import { apiUrl } from "../utils/urls.js";

function Carousel({ images, height }) {
  return (
    <div className="w-full">
      <div
        id="carouselExampleCrossfade"
        className="carousel slide carousel-fade relative"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
          {images?.map((image, index) => {
            console.log(index, image);
            if (index === 0) {
              return (
                <button
                  type="button"
                  data-bs-target="#carouselExampleCrossfade"
                  data-bs-slide-to={index}
                  className="active"
                  aria-current="true"
                  aria-label={`Slide ${index + 1}`}
                ></button>
              );
            } else {
              return (
                <button
                  type="button"
                  data-bs-target="#carouselExampleCrossfade"
                  data-bs-slide-to={index}
                  aria-label={`Slide ${index + 1}`}
                ></button>
              );
            }
          })}
        </div>
        <div
          className={`carousel-inner relative w-1/2 md:w-full h-${height} overflow-hidden`}
        >
          {images?.map((image, index) => {
            if (index === 0) {
              return (
                <div className="carousel-item active float-left w-full">
                  <img
                    src={`${apiUrl}${image.path}`}
                    className="w-full h-96 rounded-lg"
                    alt={image.id}
                  />
                </div>
              );
            } else {
              return (
                <div className="carousel-item float-left w-full">
                  <img
                    src={`${apiUrl}${image.path}`}
                    className="w-full h-96 rounded-lg"
                    alt={image.id}
                  />
                </div>
              );
            }
          })}
        </div>
        <button
          className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
          type="button"
          data-bs-target="#carouselExampleCrossfade"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon inline-block bg-no-repeat"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
          type="button"
          data-bs-target="#carouselExampleCrossfade"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon inline-block bg-no-repeat"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Carousel;
