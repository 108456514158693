import React from "react";

//COMPONENTS
import Faq from "../components/FAQ/faq";
import AddFAQ from "../components/FAQ/addFaq";
import SideBarAdmin from "../components/sidenavbar";

export default function ManageFaqs() {
  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-4 m-2 sm:m-4 w-full h-screen overflow-y-auto">
        <h1 className="text-white font-bold rounded-lg bg-bikeexplogray-light p-2 flex flex-row justify-between h-12 items-center text-xs md:text-base">
          Gestion de la F.A.Q.
          <svg
            className="w-5 h-5 mr-3 lg:w-6 lg:h-6 lg:mr-5 text-salmon"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
            ></path>
          </svg>
        </h1>
        <Faq />
        <div className="text-center">
          <AddFAQ />
        </div>
      </div>
    </div>
  );
}
