import React, { useState, useEffect } from "react";
import SideBarAdmin from "../components/sidenavbar";
import axios from "axios";
import { apiUrl, frontUrl } from "../utils/urls.js";

export default function ManageCategoriesTags({ data }) {
  const [allCategoriesOneArticle, getCategoriesOneArticle] = useState([]);
  const [allTagsArticles, getTagsArticles] = useState([]);
  const [allCategoriesTrips, getCategoriesTrips] = useState([]);
  const [allTagsPoi, getTagsPoi] = useState([]);

  useEffect(() => {
    var config2 = {
      method: "get",
      url: `${apiUrl}/api/categoryarticles`,
      headers: {},
    };

    axios(config2)
      .then(function (response) {
        getCategoriesOneArticle(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${apiUrl}/api/tagsarticle`)
      .then(function (response) {
        getTagsArticles(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });

    axios
      .get(`${apiUrl}/api/tagspoi`)
      .then(function (response) {
        getTagsPoi(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });

    axios
      .get(`${apiUrl}/api/categorytrips`)
      .then(function (response) {
        getCategoriesTrips(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, []);

  const [formData, setFormData] = useState({});
  const [cattrips, setCattrips] = useState(data);
  const [catarticles, setCatarticles] = useState(data);
  const [tags, setTags] = useState(data);
  const [tagspoi, setTagspoi] = useState(data);

  async function createCategoryTrips(e) {
    e.preventDefault();
    setCattrips({ ...cattrips, formData });

    data = formData;

    axios
      .post(`${apiUrl}/api/categorytrips`, data)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  async function createCategoryArticles(e) {
    e.preventDefault();
    setCatarticles({ ...catarticles, formData });

    data = formData;

    axios
      .post(`${apiUrl}/api/categoryarticles`, data)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  async function createTags(e) {
    e.preventDefault();
    setTags({ ...tags, formData });

    data = formData;

    axios
      .post(`${apiUrl}/api/tagsarticle`, data)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  async function createTagsPoi(e) {
    e.preventDefault();
    setTagspoi({ ...tagspoi, formData });

    data = formData;

    axios
      .post(`${apiUrl}/api/tagspoi`, data)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  async function deleteCategoryTrips(e, cattripId) {
    axios
      .delete(`${apiUrl}/api/categorytrips/${cattripId}`)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  async function deleteCategoryArticles(e, catartId) {
    axios
      .delete(`${apiUrl}/api/categoryarticles/${catartId}`)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  async function deleteTags(e, tagId) {
    axios
      .delete(`${apiUrl}/api/tagsarticle/${tagId}`)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  async function deleteTagsPoi(e, tagpoiId) {
    axios
      .delete(`${apiUrl}/api/tagspoi/${tagpoiId}`)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-2 lg:gap-4 m-2 sm:m-4 w-full h-screen overflow-y-auto">
        <h1 className="text-white text-xs md:text-base font-bold rounded-lg bg-bikeexplogray-light p-2 flex flex-row gap-2">
          <svg
            className="w-5 sm:w-6 h-5 sm:h-6"
            fill="none"
            stroke="salmon"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
            ></path>
          </svg>
          Gérer les catégories et tags{" "}
        </h1>
        <div className="flex flex-row gap-4 lg:gap-6">
          <div className="w-1/2 my-4">
            <h2 className="bg-salmon rounded-t-lg p-2 text-white text-xs md:text-base font-semibold text-center">
              Toutes les catégories de parcours
            </h2>
            <div className="bg-bikeexplogray-light rounded-b-lg flex flex-col gap-2 p-3">
              <div className="flex flex-row gap-1">
                <input
                  required
                  className="appearance-none border-2 border-bikeexplogray-dark bg-bikeexplogray-dark placeholder-slate-400 w-full text-white p-1 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs md:text-base"
                  placeholder="Créer une nouvelle catégorie de parcours"
                  onChange={(e) =>
                    setFormData({ ...formData, category: e.target.value })
                  }
                />
                <button
                  className="rounded-full bg-salmon text-white border-2 border-salmon hover:bg-white hover:text-salmon p-1 text-xs md:text-base"
                  onClick={createCategoryTrips}
                >
                  Ok
                </button>
              </div>
              <div className="flex flex-row gap-1 flex-wrap">
                {" "}
                {allCategoriesTrips.map((cattrip) => {
                  return (
                    <div className="bg-bikeexplogray-dark rounded-lg p-2 text-white flex flex-row gap-1 text-xs md:text-base">
                      {" "}
                      <button
                        className="items-center rounded-full bg-bikeexplogray-light p-1"
                        onClick={(e) => deleteCategoryTrips(e, cattrip.id)}
                      >
                        <svg
                          className="lg:w-3 lg:h-3 w-2 h-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </button>
                      <a
                        key={cattrip.id}
                        value={cattrip.id}
                        href={`${frontUrl}/categorie/${cattrip.id}`}
                      >
                        {cattrip.category}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="w-1/2 my-4">
            <h2 className="bg-salmon rounded-t-lg p-2 text-white font-semibold text-center text-xs md:text-base">
              Toutes les catégories d'articles
            </h2>
            <div className="bg-bikeexplogray-light rounded-b-lg flex flex-col gap-2 p-3">
              <div className="flex flex-row gap-1">
                <input
                  required
                  className="appearance-none text-white border-2 border-bikeexplogray-dark bg-bikeexplogray-dark placeholder-slate-400 w-full  p-1 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs md:text-base"
                  placeholder="Créer une nouvelle catégorie d'articles"
                  onChange={(e) =>
                    setFormData({ ...formData, category: e.target.value })
                  }
                />
                <button
                  className="rounded-full bg-salmon text-white border-2 border-salmon hover:bg-white hover:text-salmon p-1 text-xs md:text-base"
                  onClick={createCategoryArticles}
                >
                  Ok
                </button>
              </div>
              <div className="flex flex-row gap-1 flex-wrap">
                {" "}
                {allCategoriesOneArticle.map((cat) => {
                  return (
                    <div className="bg-bikeexplogray-dark rounded-lg p-2 text-white flex flex-row gap-1 text-xs md:text-base">
                      <button
                        className="items-center rounded-full bg-bikeexplogray-light p-1"
                        onClick={(e) => deleteCategoryArticles(e, cat.id)}
                      >
                        <svg
                          className="lg:w-3 lg:h-3 w-2 h-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </button>
                      <a
                        key={cat.id}
                        value={cat.id}
                        href={`${frontUrl}/categoriearticles/${cat.id}`}
                      >
                        {cat.category}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <div className="w-1/2">
            <h2 className="bg-salmon rounded-t-lg p-2 text-white font-semibold text-center text-xs md:text-base">
              Tous les tags pour les articles
            </h2>
            <div className="bg-bikeexplogray-light rounded-b-lg flex flex-col gap-2 p-3">
              <div className="flex flex-row gap-1">
                <input
                  required
                  className="appearance-none border-2 text-white border-bikeexplogray-dark bg-bikeexplogray-dark placeholder-slate-400 w-full  p-1 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs md:text-base"
                  placeholder="Créer un nouveau tag pour les articles"
                  onChange={(e) =>
                    setFormData({ ...formData, label: e.target.value })
                  }
                />
                <button
                  className="rounded-full bg-salmon text-white border-2 border-salmon hover:bg-white hover:text-salmon p-1 text-xs md:text-base"
                  onClick={createTags}
                >
                  Ok
                </button>
              </div>
              <div className="flex flex-row gap-1 flex-wrap">
                {allTagsArticles.map((tags) => {
                  return (
                    <div className="bg-bikeexplogray-dark rounded-lg p-2 text-white flex flex-row gap-1 text-xs md:text-base">
                      <button
                        className="items-center rounded-full bg-bikeexplogray-light p-1"
                        onClick={(e) => deleteTags(e, tags.id)}
                      >
                        <svg
                          className="lg:w-3 lg:h-3 w-2 h-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </button>
                      <div key={tags.id} value={tags.id}>
                        {tags.label}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="w-1/2">
            <h2 className="bg-salmon rounded-t-lg p-2 text-white font-semibold text-center text-xs md:text-base">
              Tous les tags pour les points d'intérêts
            </h2>
            <div className="bg-bikeexplogray-light rounded-b-lg flex flex-col gap-2 p-3">
              <div className="flex flex-row gap-1">
                <input
                  required
                  className="appearance-none border-2 text-white border-bikeexplogray-dark bg-bikeexplogray-dark placeholder-slate-400 w-full  p-1 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs md:text-base"
                  placeholder="Créer un tag pour les poi"
                  onChange={(e) =>
                    setFormData({ ...formData, label: e.target.value })
                  }
                />
                <button
                  className="rounded-full bg-salmon text-white border-2 border-salmon hover:bg-white hover:text-salmon p-1 text-xs md:text-base"
                  onClick={createTagsPoi}
                >
                  Ok
                </button>
              </div>
              <div className="flex flex-row gap-1 flex-wrap">
                {" "}
                {allTagsPoi.map((tagspoi) => {
                  return (
                    <div className="bg-bikeexplogray-dark rounded-lg p-2 text-white flex flex-row gap-1 text-xs md:text-base">
                      <button
                        className="items-center rounded-full bg-bikeexplogray-light p-1"
                        onClick={(e) => deleteTagsPoi(e, tagspoi.id)}
                      >
                        <svg
                          class="lg:w-3 lg:h-3 w-2 h-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </button>
                      <div key={tagspoi.id} value={tagspoi.id}>
                        {tagspoi.label}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
