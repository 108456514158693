import React, { useState } from "react";
import SideBarAdmin from "../../components/sidenavbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import UploadLogo from "../../components/uploadLogo";
import { apiUrl } from "../../utils/urls.js";
// REACT QUERY
import { useQuery, useMutation, useQueryClient } from "react-query";

export default function CreateClient2() {
  const [popup, setPopup] = useState(false);

  let { id } = useParams();
  let { tripId } = useParams();

  console.log(id);

  const queryKey = ["client"];
  const getClients = async () => {
    const { data } = await axios.get(
      `${apiUrl}/api/clients/${id}`
    );
    return data;
  };

  const useClient = () => useQuery(queryKey, getClients);
  const {
    data: client,
    isSuccess: clientIsSuccess,
    isLoading: clientIsLoading,
    isError: clientIsError,
  } = useClient();

  // DELETE PICTURE
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    async (pictureid) => {
      let url = `${apiUrl}/api/pictures/${pictureid}`;
      await axios
        .delete(url)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    {
      onSuccess: () => {
        queryClient?.invalidateQueries(queryKey);
      },
    }
  );

  function goBack() {
    document.location.pathname = "/parcours/" + tripId;
  }

  return (
    <div className="flex flex-row gap-2 md:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-2 lg:gap-4 w-full h-full overflow-y-auto m-2 lg:m-4">
        <h1 className="rounded-lg bg-bikeexplogray-light text-white font-bold p-2 px-3 flex flex-row gap-2 h-10 sm:h-12 w-full items-center text-xs md:text-base">
          <svg
            className="w-5 sm:w-6 h-5 sm:h-6"
            fill="none"
            stroke="salmon"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            ></path>
          </svg>{" "}
          Créer un client{" "}
        </h1>

        <div className="flex flex-col gap-2 md:gap-4 lg:my-4">
          <div className="flex flex-col gap-2 lg:gap-4">
            <div className="">
              <h2 className="rounded-t-lg text-center font-semibold text-white bg-salmon py-1 text-xs md:text-base">
                Infos client
              </h2>
              <div className="flex flex-col gap-2 rounded-b-lg bg-bikeexplogray-light p-2 sm:p-3 text-white text-xs md:text-base">
                <div className="flex flex-col sm:flex-row sm:gap-4">
                  <label className="text-xs sm:text-base font-semibold ">
                    Nom du magasin :{" "}
                  </label>
                  <div className=" text-xs sm:text-base ">
                    {client?.storeName}
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row sm:gap-4">
                  <label className=" text-xs sm:text-base font-semibold ">
                    Site web :{" "}
                  </label>
                  <div className="text-xs sm:text-base"> {client?.website}</div>
                </div>
                <div className="text-center">
                  <a href="/majprofil">
                    {" "}
                    <button className="text-xs sm:text-base p-1 sm:w-1/2 rounded-full bg-salmon text-center shadow-xl border-2 border-salmon hover:bg-white hover:text-salmon">
                      Modifier
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <h2 className="rounded-t-lg text-center font-semibold text-white bg-salmon py-1 text-xs md:text-base">
              Logo
            </h2>
            <div className="rounded-b-lg bg-bikeexplogray-light p-3 text-white flex flex-col justify-center">
              {clientIsSuccess && client?.pictures[0] !== undefined && (
                <img
                  src={`${apiUrl}${client.pictures[0].path}`}
                  alt="vignette du parcours"
                  className="rounded-lg h-32 object-contain w-auto my-2"
                />
              )}
              {clientIsSuccess && client?.pictures[0] === undefined && (
                <UploadLogo clientId={id} />
              )}

              {clientIsSuccess && client?.pictures[0] !== undefined && (
                <div className="text-center">
                  <button
                    className="text-white rounded-full p-1 sm:w-1/2 bg-salmon border-2 border-salmon hover:bg-white hover:text-salmon text-xs md:text-base"
                    type="button"
                    onClick={(e) => {
                      mutate(client.pictures[0].id);
                      setPopup(true);
                      setTimeout(() => setPopup(false), 2000);
                    }}
                  >
                    Supprimer une image
                  </button>
                </div>
              )}
              {isSuccess && popup && (
                <div className="text-white my-2 rounded-full bg-green-500 border-2 text-center border-green-500 text-xs">
                  Logo supprimé
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-center mt-6">
          <button
            className="text-salmon rounded-full p-1 sm:w-1/2 border-2 border-salmon hover:bg-white hover:text-salmon text-xs md:text-base"
            onClick={goBack}
          >
            Retour au parcours
          </button>
        </div>
      </div>
    </div>
  );
}
