import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl, frontUrl } from "../utils/urls.js";

// COMPONENTS
import Upload from "../components/upload";
import GeoTripsPoi from "../components/GeoTripsPoi/geotripspoi";
import SideBarAdmin from "../components/sidenavbar";
import AddClientButton from "../components/addClientButton";
import UploadGpx from "../components/uploadgpx";

// REACT QUERY
import { useQuery, useMutation, useQueryClient } from "react-query";

// DRAG AND DROP
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function OneParcoursAdmin({ data }) {
  const [formData, setFormData] = useState({});
  const [parcours, setParcours] = useState();
  const [confirm, setConfirm] = useState({});
  const [status, setStatus] = useState();
  const [level, setLevel] = useState("");

  //scrolling menu select clients
  const [allClients, getClients] = useState();
  const [popup, setPopup] = useState(false);

  //scrolling menu select categories
  const [allCategories, getCategories] = useState([]);

  //scrolling menu select poi
  const [allPoi, getPoi] = useState([]);

  let { id } = useParams();

  async function updateParcours(e) {
    e.preventDefault();
    setParcours({ ...parcours, formData });

    data = formData;

    axios
      .put(`${apiUrl}/api/trips/${id}`, data)
      .then(function (response) {
        setParcours(response.data);
        //confirmation message
        const mess = "Votre parcours a bien été modifié";

        setConfirm({ ...confirm, mess });

        setTimeout(function () {
          // after 2 seconds
          document.getElementById("confirmmessage").style.visibility = "hidden";
        }, 2000);

        setTimeout(function () {
          // after 2 seconds
          document.location.pathname = "/";
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
      });
    updateLevel();
  }

  function updateLevel() {
    axios.put(`${apiUrl}/api/trips/${id}`, { elevation: level });
  }

  async function deleteParcours(e) {
    e.preventDefault();

    axios
      .delete(`${apiUrl}/api/trips/${id}`, data)
      .then(function (response) {
        setStatus("Supprimé");
        document.location.pathname = "/";
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function onChangeHandler() {
    // GET SELECT VALUE
    if (document.getElementById("categorie") != null) {
      let selectvalue = document.getElementById("categorie").value;

      // GET ID ON SELECT VALUE
      let selectid = selectvalue.split(" ");
      return selectid[0];
    }
  }

  //associate category to trip
  async function addCategoryToParcours() {
    let selectedValue = onChangeHandler();

    var data = JSON.stringify({
      trip_id: id,
      categorytrip_id: selectedValue,
    });

    var config = {
      method: "post",
      url: `${apiUrl}/api/category_trips`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //delete category from trip
  async function deleteCat(e, catid) {
    axios
      .delete(`${apiUrl}/api/category_trips/cat/${catid}/${id}`)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
    window.location.reload();
  }

  function clientChangeHandler() {
    // GET SELECT VALUE
    if (document.getElementById("client") != null) {
      let selectedvalue = document.getElementById("client").value;

      // GET ID ON SELECT VALUE
      let clientid = selectedvalue.split(" ");
      return clientid[0];
    }
  }

  function poiChangeHandler() {
    // GET SELECT VALUE
    if (document.getElementById("poi") != null) {
      let selectedpoi = document.getElementById("poi").value;

      // GET ID ON SELECT VALUE
      let poiid = selectedpoi.split(" ");
      return poiid[0];
    }
  }

  function addClientToParcours() {
    let client = clientChangeHandler();

    var data = JSON.stringify({
      clientId: client,
    });

    var config = {
      method: "put",
      url: `${apiUrl}/api/trips/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }

  async function addPoiToParcours() {
    let pointofinterest = poiChangeHandler();

    var data = JSON.stringify({
      trip_id: id,
      pointOfInterest_id: pointofinterest,
    });

    var config = {
      method: "post",
      url: `${apiUrl}/api/pointTrip/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config).catch(function (error) {
      console.log(error);
    });
  }

  //handle checkboxes
  async function handleCheckBoxPremium() {
    if (document.getElementById("premium").checked === true) {
      await setFormData({ ...formData, premium: 1 });
    } else await setFormData({ ...formData, premium: 0 });
  }

  async function handleCheckBoxPublished() {
    if (document.getElementById("published").checked === true) {
      await setFormData({ ...formData, published: false });
    } else await setFormData({ ...formData, published: true });
  }

  //show trip and categorie list

  const queryKey = ["trip"];
  const getTrip = async () => {
    const { data } = await axios.get(`${apiUrl}/api/trips/${id}`);
    return data;
  };

  const useTrip = () => useQuery(queryKey, getTrip);
  const {
    data: trip,
    isSuccess: tripIsSuccess,
    isLoading: tripIsLoading,
    isError: tripIsError,
  } = useTrip();

  // DELETE POI
  const { mutate: deletePoi, isSuccess: deletePoiSuccess } = useMutation(
    async (poi_id) => {
      let url = `${apiUrl}/api/pointTrip/${poi_id}`;
      await axios
        .delete(url)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  // CHANGE RANK OF POI
  const { mutate: changeRank, isSuccess: changeRankSuccess } = useMutation(
    async ({ pointTrip, rank }) => {
      let url = `${apiUrl}/api/pointTrip/${pointTrip}`;
      await axios
        .put(url, { rank })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  // DELETE PICTURE
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    async (pictureid) => {
      let url = `${apiUrl}/api/pictures/${pictureid}`;
      await axios
        .delete(url)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  // DRAG AND DROP
  /*   const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let tempPoi = [...trip.pointsOfInterest];
    let [selectedRow] = tempPoi.splice(result.source.index, 1);
    changeRank({
      pointTrip: selectedRow.PointTrip.id,
      rank: result.destination.index + 1,
    });
  }; */

  // DUPLICATE TRIP
  /*   const { mutate: duplicateTrip, isSuccess: duplicateTripSuccess } =
    useMutation(
      async () => {
        let url = `https://api.bikeexplo.com/api/trips/duplicate`;

        await axios
          .post(url, trip)
          .then((res) => {
            console.log(res.data);
            document.location.pathname = "/parcours/" + res.data.id;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
        },
      }
    ); */

  useEffect(() => {
    var config2 = {
      method: "get",
      url: `${apiUrl}/api/categorytrips`,
      headers: {},
    };

    axios(config2)
      .then(function (response) {
        getCategories(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${apiUrl}/api/clients`)
      .then(function (response) {
        getClients(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });

    //show poi list
    axios
      .get(`${apiUrl}/api/pointsofinterest`)
      .then(function (response) {
        getPoi(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });

    //Default values in input and textarea
    document.getElementById("description").defaultValue = trip?.description;
    document.getElementById("titre").defaultValue = trip?.title;
    document.getElementById("city").defaultValue = trip?.city;
    setLevel(trip?.elevation);
  }, [trip]);

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-2 lg:gap-4 m-2 sm:m-4 w-full h-screen overflow-y-auto">
        {/*  en-tête */}
        <h1 className="text-white font-bold rounded-lg bg-bikeexplogray-light p-2 flex flex-row gap-1 sm:gap-2 h-10 lg:h-12 items-center text-xs md:text-base">
          <svg
            className="w-5 h-5 lg:w-6 lg:h-6 "
            fill="salmon"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
              clipRule="evenodd"
            ></path>
          </svg>
          Le parcours{" "}
        </h1>
        {/* message de confirmation/erreur */}
        <div
          id="confirmmessage"
          className="rounded-full bg-green-400 text-white text-center w-full text-xs md:text-base"
        >
          {" "}
          {confirm.mess}{" "}
        </div>
        {/* Partie 1: Vignette, titre, description et client */}
        <div className="flex flex-row gap-8 sm:gap-12 justify-center">
          <div className="flex flex-col gap-4 w-1/2">
            <div className="flex flex-col md:flex-row gap-2 h-1/6">
              <label className="text-white sm:w-2/6 text-xs md:text-base">
                Titre
              </label>
              <input
                id="titre"
                name="titre"
                className="appearance-none rounded-lg relative block sm:w-4/5 px-3 py-2 bg-bikeexplogray-light border-bikeexplogray-light border-2 placeholder-gray-200 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 text-xs md:text-sm"
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col md:flex-row gap-2 h-3/6">
              <label className="text-white sm:w-2/6 text-xs md:text-base">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                type="text"
                className="overflow-auto self-start sm:w-4/5 h-full inline-block align-top appearance-none bg-bikeexplogray-light border-bikeexplogray-light rounded-lg placeholder-gray-200 text-white text-xs md:text-sm focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 "
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              ></textarea>
            </div>
            <div className="flex flex-col md:flex-row gap-2 h-2/6">
              <label className="text-white sm:w-2/6 text-xs md:text-base">
                Client
              </label>
              <div className="flex flex-col bg-bikeexplogray-light rounded-lg sm:w-4/5">
                <div className="h-1/2 mx-2 mt-2 text-white text-xs md:text-sm">
                  {trip?.client?.storeName}
                </div>
                <div className="flex flex-row gap-2 h-1/2 mx-2 mb-2 align-center">
                  <select
                    id="client"
                    name="client"
                    className="h-7 sm:h-9 appearance-none rounded-lg relative block w-full px-3 py-2 bg-bikeexplogray-dark border-bikeexplogray-dark text-bikeexplogray-light text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 text-xs md:text-sm"
                    placeholder="Nom du client"
                    onChange={clientChangeHandler}
                  >
                    <option className="text-xs md:text-sm">
                      Modifier le client
                    </option>
                    {allClients?.map((client) => {
                      return (
                        <option
                          key={client.id}
                          value={client.id}
                          className="text-xs md:text-base"
                        >
                          {client.id} {client.storeName}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="bg-salmon text-white rounded-full p-1 border-2 border-salmon hover:text-salmon hover:bg-white text-xs md:text-sm"
                    onClick={addClientToParcours}
                  >
                    OK
                  </button>
                  <AddClientButton id={id} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/2 gap-2">
            {" "}
            {tripIsSuccess && trip.pictures[0] !== undefined && (
              <img
                src={`${apiUrl}${trip.pictures[0].path}`}
                alt="vignette du parcours"
                className="rounded-lg h-60 object-contain w-auto"
              />
            )}
            {tripIsSuccess && trip.pictures[0] === undefined && (
              <img
                src={``}
                alt="vignette du parcours"
                className="rounded-lg bg-salmon h-60 text-xs text-white p-2"
              />
            )}{" "}
            {tripIsSuccess && trip.pictures[0] !== undefined && (
              <button
                className="text-white rounded-full p-1 bg-salmon border-2 border-salmon hover:bg-white hover:text-salmon text-xs md:text-base"
                type="button"
                onClick={(e) => {
                  mutate(trip.pictures[0].id);
                  setPopup(true);
                  setTimeout(() => setPopup(false), 2000);
                }}
              >
                Supprimer une image
              </button>
            )}
            {isSuccess && popup && (
              <div className="text-white rounded-full p-1 bg-green-500 border-2 text-center border-green-500 ext-xs md:text-base">
                Image supprimée
              </div>
            )}
            {tripIsSuccess && trip.pictures[0] === undefined && (
              <Upload tripId={id} />
            )}
            <UploadGpx id={id} />
          </div>
        </div>
        {/*  Partie 2 : Catégories, Carte et Résumé du parcours */}
        <div className="flex flex-row w-full gap-5 h-2/6 mb-6">
          <div className="w-full md:w-1/5">
            <h1 className="bg-salmon text-white rounded-t-lg p-1 text-center text-xs md:text-base">
              Catégories
            </h1>
            <div className="flex flex-col gap-2 bg-bikeexplogray-light rounded-b-lg p-2 h-5/6">
              <div className="self-center flex flex-row gap-2 w-5/6">
                <select
                  type="text"
                  placeholder="Catégorie"
                  id="categorie"
                  onChange={onChangeHandler}
                  className=" appearance-none w-full rounded-lg bg-bikeexplogray-dark  border-bikeexplogray-dark text-bikeexplogray-light focus:ring-salmon focus:border-salmon focus:z-10"
                >
                  <option className="text-xs md:text-sm">
                    Ajouter une catégorie
                  </option>
                  {allCategories.map((cat) => {
                    return (
                      <option
                        key={cat.id}
                        value={cat.id}
                        className="text-xs md:text-sm"
                      >
                        {cat.id} {cat?.category}
                      </option>
                    );
                  })}
                </select>
                <button
                  className="bg-salmon text-white rounded-full p-1 border-2 border-salmon hover:text-salmon hover:bg-white text-xs md:text-sm"
                  onClick={addCategoryToParcours}
                >
                  OK
                </button>
              </div>
              <div className="self-center flex flex-row flex-wrap gap-2 overflow-y-auto">
                {tripIsSuccess &&
                  trip.categories?.map((cat) => {
                    return (
                      <div
                        className="flex flex-row gap-1 rounded-lg bg-bikeexplogray-dark p-1 sm:p-2 text-white text-xs md:text-sm"
                        key={cat.id}
                      >
                        <button
                          className="items-center rounded-full bg-bikeexplogray-light p-1"
                          onClick={(e) => deleteCat(e, cat.id)}
                        >
                          <svg
                            className="lg:w-3 lg:h-3 w-2 h-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </button>
                        {cat.category}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="w-full md:w-3/5 h-full">
            <h1 className="bg-salmon text-white rounded-t-lg p-1 text-center text-xs md:text-base">
              Carte
            </h1>
            <div className="flex flex-col gap-2 justify-center bg-bikeexplogray-light rounded-b-lg p-2 h-full mb-12">
              <GeoTripsPoi />
            </div>
          </div>
          <div className="w-full md:w-1/5">
            <h1 className="bg-salmon text-white rounded-t-lg p-1 text-center text-xs md:text-base">
              Résumé du parcours
            </h1>
            <div className="flex flex-col gap-2 justify-center bg-bikeexplogray-light rounded-b-lg p-2 h-5-6">
              <div className="flex flex-row gap-1 text-xs md:text-sm">
                <label className="text-white w-2/3">Durée (h):</label>
                <input
                  id="durée"
                  name="durée"
                  type="number"
                  className="w-2/3 border-bikeexplogray-dark appearance-none rounded-lg bg-bikeexplogray-dark placeholder-gray-200 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                  placeholder={trip?.duration}
                  onChange={(e) =>
                    setFormData({ ...formData, duration: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-row text-xs md:text-sm">
                <label className="text-white w-2/3">Difficulté:</label>
                <select
                  type="text"
                  placeholder="Niveau"
                  id="niveau"
                  value={level}
                  onChange={(e) => setLevel(e.target.value)}
                  className="h-10 appearance-none w-2/3 rounded-lg
                      bg-bikeexplogray-dark border-bikeexplogray-dark
                      text-bikeexplogray-light focus:ring-salmon
                      focus:border-salmon focus:z-10 text-xs sm:text-base"
                >
                  <option className="text-xs md:text-sm">
                    {trip?.elevation}
                  </option>
                  <option>néo-cycliste</option>
                  <option>cycliste occasionel</option>
                  <option>cycliste habitué</option>
                  <option>cycliste sportif</option>
                </select>
              </div>
              <div className="flex flex-row text-xs md:text-sm">
                <label className="text-white w-2/3">Distance (km):</label>
                <input
                  id="distance"
                  name="distance"
                  type="number"
                  className="w-2/3 border-bikeexplogray-dark appearance-none rounded-lg bg-bikeexplogray-dark placeholder-gray-200 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                  placeholder={trip?.distance}
                  onChange={(e) =>
                    setFormData({ ...formData, distance: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-row text-xs md:text-sm">
                <label className="text-white w-2/3">Ville :</label>
                <input
                  id="city"
                  name="city"
                  type="text"
                  className="w-2/3 border-bikeexplogray-dark appearance-none rounded-lg bg-bikeexplogray-dark placeholder-gray-200 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                  onChange={(e) =>
                    setFormData({ ...formData, city: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {/*  Partie 3 : Etapes/POI */}
        <div className="self-center flex flex-col gap-2 w-full">
          <div className="flex flex-row gap-2">
            <select
              type="text"
              placeholder="Point d'intérêt"
              id="poi"
              onChange={poiChangeHandler}
              className="w-full border-salmon appearance-none rounded-lg bg-bikeexplogray-dark text-bikeexplogray-light focus:ring-salmon focus:border-salmon focus:z-10 text-xs md:text-sm"
            >
              <option className="text-xs md:text-sm">
                Ajouter un point d'intérêt
              </option>
              {allPoi.map((poi) => {
                return (
                  <option
                    key={poi.id}
                    value={poi.id}
                    className="text-xs md:text-sm"
                  >
                    {poi.id} {poi?.title}
                  </option>
                );
              })}
            </select>
            <button
              className="bg-salmon text-white rounded-full p-1 border-2 border-salmon hover:text-salmon hover:bg-white text-xs md:text-sm"
              onClick={addPoiToParcours}
            >
              Ok
            </button>
          </div>
          {tripIsSuccess && trip?.pointsOfInterest?.length !== 0 && (
            <div className="flex flex-col mt-4">
              {" "}
              <h2 className="text-white bg-salmon rounded-t-lg w-full h-9 text-center items-center flex justify-center text-xs md:text-base">
                Points d'intérêt sur le parcours
              </h2>
              {isSuccess && (
                <div className="bg-green-500 text-white text-center flex items-center justify-center text-xs lg:font-bold lg:text-sm">
                  Point d'intérêt supprimé !
                </div>
              )}
              <DragDropContext /* onDragEnd={(results) => handleDragEnd(results)} */
              >
                <table className="bg-bikeexplogray-light w-full text-xs md:text-sm">
                  <thead>
                    <tr className="text-salmon text-xs sm:text-sm lg:text-base bg-bikeexplogray-dark h-8 lg:h-10">
                      {/* <th className="text-white">Action</th> */}
                      <th className="text-white">Nom</th>
                      <th className="text-white">Position</th>
                      <th className="text-white">Supprimer</th>
                    </tr>
                  </thead>
                  <Droppable droppableId="tbody">
                    {(provided) => (
                      <tbody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {tripIsSuccess &&
                          trip.pointsOfInterest?.map((item, index) => {
                            return (
                              <Draggable
                                draggableId={item.title}
                                index={index}
                                key={item.id}
                              >
                                {(provided) => (
                                  <tr
                                    className="text-white even:bg-bikeexplogray-dark"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                  >
                                    {/*  <td
                                      className="flex justify-center items-center my-2 h-10"
                                      {...provided.dragHandleProps}
                                    >
                                      <svg
                                        class="w-6 h-6"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
                                        ></path>
                                      </svg>
                                    </td> */}
                                    <td className="text-center my-5 mx-2 h-10 hover:text-salmon hover:font-bold">
                                      <a
                                        href={`${frontUrl}/editPoi/${item?.id}`}
                                      >
                                        {item?.title}
                                      </a>
                                    </td>
                                    <td className="text-center my-5 mx-2 h-10">
                                      <input
                                        className="w-10 text-center outline-none rounded-lg h-full bg-transparent"
                                        placeholder={item.PointTrip.rank}
                                        onChange={(e) => {
                                          changeRank({
                                            pointTrip: item.PointTrip.id,
                                            rank: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                    <td className="text-center my-5 mx-2 h-10">
                                      <button
                                        onClick={(e) => {
                                          deletePoi(item.PointTrip.id);
                                        }}
                                      >
                                        <svg
                                          id="deleteBtn"
                                          className="w-4 h-4 ml-2 lg:w-6 lg:h-6 lg:ml-5"
                                          fill="none"
                                          stroke="salmon"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                          ></path>
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </DragDropContext>
              <div className="w-full bg-salmon h-2 rounded-b-lg"></div>
            </div>
          )}
        </div>
        {/*  Partie 4 : codes */}
        {trip?.codes.length !== 0 && (
          <div className="flex flex-col mt-4">
            <h2 className="text-white bg-salmon rounded-t-lg w-full h-9 text-center items-center flex justify-center text-xs md:text-base p-2">
              Codes déclenchés mais non utilisés pour le parcours
            </h2>
            <div className="text-white bg-bikeexplogray-light rounded-b-lg w-full h-full flex flex-row gap-4 text-center items-center justify-center text-xs md:text-sm p-2">
              {trip?.codes?.map((item) => {
                return (
                  <p key={item.id} value={item.id}>
                    {item?.code}
                  </p>
                );
              })}
            </div>
          </div>
        )}
        {/* Partie 5 : Réglages premium, brouillon, restreint */}
        <div className="flex flex-row justify-evenly md:justify-center md:gap-6 w-full my-7">
          <div className="flex flex-row justify-between gap-2 bg-bikeexplogray-light rounded-lg p-2 w-1/3 md:w-1/4 text-sm md:text-base">
            <label className="text-white">Premium</label>
            <input
              id="premium"
              name="premium"
              type="checkbox"
              defaultChecked={false}
              className="appearance-none mr-2 rounded-full focus:ring-salmon"
              onClick={handleCheckBoxPremium}
            />
          </div>
          <div className="flex flex-row justify-between gap-2 bg-bikeexplogray-light rounded-lg p-2 w-1/3 md:w-1/4 text-sm md:text-base">
            <label className="text-white">Brouillon</label>
            <input
              id="published"
              name="published"
              type="checkbox"
              defaultChecked={true}
              className="appearance-none mr-2 rounded-full focus:ring-salmon"
              onClick={handleCheckBoxPublished}
            />
          </div>
        </div>
        {/* Partie 6 : Dupliquer */}
        {/*  <div className="flex flex-row justify-center gap-2 w-full mt-4">
          <button
            className="bg-salmon  rounded-full p-2 text-white border-2 border-salmon hover:text-salmon hover:bg-white w-1/3"
            onClick={duplicateTrip}
          >
            Dupliquer
          </button>
        </div> */}
        {/* Partie 6 : Confirmation / Suppression */}
        <div className="flex flex-row gap-2 w-full mb-12 mt-4">
          <button
            className="bg-salmon  rounded-full p-2 text-white border-2 border-salmon hover:text-salmon hover:bg-white w-1/2 text-sm md:text-base"
            onClick={updateParcours}
          >
            Modifier
          </button>
          <button
            className="border-2 border-salmon text-salmon rounded-full hover:bg-white p-2 w-1/2 text-sm md:text-base"
            onClick={deleteParcours}
          >
            Supprimer
          </button>{" "}
        </div>
      </div>
    </div>
  );
}
