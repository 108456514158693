import React, { useState } from "react";
import SideBarAdmin from "../../components/sidenavbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../utils/urls.js";

export default function CreateClient({ data }) {
  const [formData, setFormData] = useState({});
  const [client, setClient] = useState(data);
  const [confirm, setConfirm] = useState({});
  const [showButton, setShowButton] = useState(false);
  const [hideButton, setHideButton] = useState(true);

  let { tripId } = useParams();

  async function createClient(e) {
    e.preventDefault();
    console.log("prout");
    setClient({ ...client, formData });

    var axios = require("axios");
    var data = formData;
    var config = {
      method: "post",
      url: `${apiUrl}/api/clients/addbyadmin`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setClient(response.data);
        console.log("ok");
        const mess = "Le client a bien été créé";
        console.log(client);

        setConfirm({ ...confirm, mess });
      })
      .catch(function (error) {
        console.log("échec");
        console.log(error);
      });

    setShowButton(true);
    setHideButton(false);
  }

  function NextStep() {
    const ID = client.id;
    console.log("ID=", ID);

    //confirmation message
    const mess =
      "Vous allez maintenant passer à la deuxième étape de création du client et lui rajouter un logo";

    setConfirm({ ...confirm, mess });
    setTimeout(function () {
      // after 1,5 seconds
      document.getElementById("confirmmessage").style.visibility = "hidden";
    }, 1500);
    setTimeout(function () {
      // after 1,5 seconds
      document.location.pathname = "/addclient2/" + ID + "/" + tripId;
    }, 1500);
  }

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-2 w-full h-screen overflow-y-auto m-2">
        <h1 className="rounded-lg bg-bikeexplogray-light text-white font-bold p-2 px-3 flex flex-row gap-2 h-10 sm:h-12 items-center text-xs md:text-base">
          <svg
            className="w-5 sm:w-6 h-5 sm:h-6"
            fill="none"
            stroke="salmon"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            ></path>
          </svg>{" "}
          Créer un client
        </h1>{" "}
        <div className="bg-green-500 rounded-lg text-white text-center text-xs w-full sm:text-base">
          {" "}
          {confirm.mess}
        </div>
        <div
          className="self-center w-full md:w-2/3 mb-10 flex flex-col gap-6"
          //enctype="multipart/form-data"
        >
          <div className="rounded-md shadow-sm -space-y-px flex flex-col gap-4">
            <div>
              <label htmlFor="store-name" className="text-white">
                Nom du magasin
              </label>
              <input
                id="store-name"
                name="store-name"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="Nom du magasin"
                onChange={(e) =>
                  setFormData({ ...formData, storeName: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="website" className="text-white">
                Site web
              </label>
              <input
                id="website"
                name="website"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder="www.monsite.com"
                onChange={(e) =>
                  setFormData({ ...formData, website: e.target.value })
                }
              />
            </div>
          </div>
          {hideButton ? (
            <button
              onClick={createClient}
              className="text-xs md:text-base w-full p-1 bg-salmon text-sm font-medium rounded-lg text-white border-2 border-salmon hover:bg-white hover:text-salmon focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-salmon"
            >
              Créer le client
            </button>
          ) : null}
          {showButton ? (
            <button
              className="border-2 border-salmon text-salmon rounded-full hover:bg-white p-2 w-full text-xs sm:text-base"
              onClick={NextStep}
            >
              Passer à l'étape 2
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
