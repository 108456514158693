import React, { useState } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";
import LogoTransp from "./logoBEtransp.png";
import axios from "axios";
import { apiUrl } from "../utils/urls.js";

// ERRORS FORM
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function LoginAdmin({ data }) {
  // ERRORS VALIDATIONS
  let schema = yup.object().shape({
    email: yup
      .string()
      .email("Vous devez rentrer un email valide")
      .required("L'email est requis !"),
    password: yup.string().min(8, "Le mot de passe doit comporter au moins 8 caractères").required("Le mot de passe est requis !"),
  });

  // ERRORS VARS
  const {
    register,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const [formData, setFormData] = useState({});
  const [admin, setAdmin] = useState(data);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [emailError, setEmailError] = useState({
    message: "",
    showError: false,
    errorMessage: false,
  });
  const [error, setError] = useState("");
  //const [confirm, setConfirm] = useState({});

  //Show/Hide password
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  async function connection(e) {
    e.preventDefault();
    setAdmin({ ...admin, formData });

    var data = formData;
    var config = {
      method: "post",
      url: `${apiUrl}/api/admins/signin`,
      // headers: {
      //   "Access-Control-Allow-Headers":
      //     "x-access-token, Origin, Content-Type, Accept",
      // },
      data: data,
    };

    axios(config)
      .then(function (response) {
        var token = JSON.stringify(response.data.accessToken);
        token = token.replace(/^"(.*)"$/, "$1");
        localStorage.setItem("token", token);
        setIsAuthenticated(true);

        document.location.pathname = "/";
      })
      .catch(function (error) {
        console.log(error);
        setError("Email ou mot de passe incorrect");
      });
  }

  async function sendEmail(e) {
    e.preventDefault();
    if (formData.email === undefined || formData.email === "") {
      setEmailError({
        message: "Veuillez entrer un email !",
        showError: true,
        errorMessage: true,
      });
    } else {
      setEmailError({
        message: "Email envoyé !",
        showError: true,
        errorMessage: false,
      });
      axios
        .post(`${apiUrl}/api/admins/forgotPassword`, {
          email: formData.email,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <div className="bg-bikeexplogray-dark min-h-full h-screen w-screen flex justify-center px-4 sm:px-6 lg:px-8 overflow-y-auto">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-44 sm:h-60 w-auto"
            src={LogoTransp}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-base sm:text-2xl font-extrabold text-white">
            Connectez-vous à votre compte Bike Explo Admin.
          </h2>
        </div>
        <div className="bg-red-500 rounded-lg text-white text-center w-full">
          {error}
        </div>
        {errors.email && (
          <p className="flex items-center font-medium tracking-wide text-red-500 text-xs">
            {errors.email?.message}
          </p>
        )}
        {errors.password && (
          <p className="flex items-center font-medium tracking-wide text-red-500 text-xs">
            {errors.password?.message}
          </p>
        )}
        <form className="mt-8 space-y-6" onSubmit={connection}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-lg">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email
              </label>
              <input
                id="email"
                {...register("email")}
                className={
                  errors.email
                    ? "appearance-none rounded-none relative block w-full px-3 py-2 border-2 border-red-500 placeholder-gray-500 text-gray-900 rounded-t-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
                    : "appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 rounded-t-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
                }
                placeholder="Email"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>
            <div className="flex flex-row relative">
              <label htmlFor="password" className="sr-only">
                Mot de passe
              </label>
              <input
                id="password"
                type={passwordShown ? "text" : "password"}
                {...register("password")}
                className={
                  errors.password
                    ? "appearance-none rounded-none relative block w-full px-3 py-2 border-2 border-red-500 placeholder-gray-500 text-gray-900 rounded-b-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-base"
                    : "appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 rounded-b-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-base"
                }
                placeholder="Mot de passe"
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
              <button
                className="absolute inset-y-0 right-0 pr-2 text-salmon hover:text-bikeexplogray-light"
                onClick={togglePassword}
              >
                <svg
                  className="w-5 h-5 sm:w-6 sm:h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>

          <div className="flex flex-row justify-between gap-2">
            <div className="flex flex-row justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-xs sm:text-sm text-white"
                >
                  Se souvenir de moi
                </label>
              </div>
            </div>
            <div className="text-sm text-white">
              <button
                onClick={sendEmail}
                className="hover:text-salmon font-medium text-xs sm:text-sm"
              >
                Mot de passe oublié ?
              </button>
              {emailError.errorMessage ? (
                <p className="flex items-center font-medium hover:text-salmon tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {emailError.message}
                </p>
              ) : (
                <p className="flex items-center font-medium tracking-wide text-green-500 text-xs mt-1 ml-1">
                  {emailError.message}
                </p>
              )}
            </div>
          </div>
          <div className="hidden text-white text-xs sm:text-sm text-center">
            Vous n'avez pas encore de compte ? Inscrivez-vous{" "}
            <a href="/inscription" className="text-salmon">
              ici
            </a>
          </div>

          <div>
            <button
              type="submit"
              onClick={connection}
              disabled={isSubmitting || !isValid}
              className="group relative w-full flex justify-center py-2 px-4 bg-salmon text-sm font-medium rounded-lg text-white text-xs sm:text-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-salmon"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              Se connecter
            </button>
            {!isValid && (
              <p className="flex items-center tracking-wide text-red-500 text-xs mt-1 ml-1">
                Formulaire non valide, veuillez remplir tous les champs !
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
