import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { TrashIcon } from "@heroicons/react/solid";
import axios from "axios";
import { apiUrl } from "../../utils/urls.js";

export default function DeleteFAQ(props) {
  const queryClient = useQueryClient();
  const queryKey = ["faqs"];
  const { mutate, isSuccess } = useMutation(
    async () => {
      let url = `${apiUrl}/api/faqs/${props.id}`;
      await axios
        .delete(url)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        window.location.reload();
      },
    }
  );

  return (
    <button
      className="mt-2 bg-salmon hover:bg-white rounded-full text-white hover:text-salmon p-2"
      onClick={mutate}
    >
      <TrashIcon className="w-4 h-4"></TrashIcon>
    </button>
  );
}
