import React, { useState } from "react";
import SideBarAdmin from "../../components/sidenavbar";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { apiUrl, frontUrl } from "../../utils/urls.js";

export default function AllClientsAdmin() {
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState(allData);
  const [wordEntered, setWordEntered] = useState("");

  const queryKey = ["filteredData"];
  const { isLoading, data } = useQuery(queryKey, () => loadClients());
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    async (id) => {
      let url = `${apiUrl}/api/clients/${id}`;
      await axios
        .delete(url)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  // PAGINATION
  const PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * PER_PAGE;
  const totalclients = filteredData?.length;
  const pageCount = Math.ceil(totalclients / PER_PAGE);

  const currentPageData = filteredData
    ?.slice(offset, offset + PER_PAGE)
    .map((value) => {
      return (
        <tr
          key={value.id}
          className={
            value.created_by_admin
              ? "text-center text-salmon even:bg-bikeexplogray-light text-xs sm:text-sm lg:text-base"
              : "text-center even:bg-bikeexplogray-light text-xs sm:text-sm lg:text-base"
          }
        >
          <td className=" p-2">
            <a href={`${frontUrl}/parcoursclient/${value.id}`}>
              {value.storeName}
            </a>
          </td>

          <td className="p-2">{value.email}</td>
          <td className="p-2">{value.phone}</td>
          <td className="p-2 hidden sm:hidden lg:block">{value.credit}</td>
          {/* <td className="p-2">{value.adress}</td> */}
          {/* <td className="p-2 hidden sm:hidden lg:block">{value.zipcode}</td> */}
          <td className="p-2">{value.city}</td>
          <td className="p-2">{value.website}</td>
          <td className="p-2">
            <a href={`${frontUrl}/profilclient/${value.id}`}>
              <svg
                class="w-4 h-4 lg:w-6 lg:h-6 text-salmon hover:text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                ></path>
              </svg>
            </a>
          </td>
          <td className="p-2">
            <button onClick={(e) => mutate(value.id)}>
              <svg
                className="w-4 h-4 lg:w-6 lg:h-6 text-salmon hover:text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                ></path>
              </svg>
            </button>
          </td>
        </tr>
      );
    });

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  function loadClients() {
    axios
      .get(`${apiUrl}/api/clients/`)
      .then((response) => {
        console.log("Client" + response.data);
        setAllData(response.data);
        setFilteredData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSearch = (event) => {
    const searchWord = event.target.value;

    setWordEntered(searchWord);

    const newFilter = allData.filter((value) => {
      return (
        value.city.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.storeName.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.username.toLowerCase().includes(searchWord.toLowerCase())
      );
    });

    setFilteredData(newFilter);
  };

  return (
    <div className="flex flex-row gap-1 sm:gap-2 lg:gap-4 bg-bikeexplogray-dark ">
      <SideBarAdmin />
      <div className="flex flex-col gap-4 m-2 sm:m-4">
        <div className="flex flex-row justify-between items-center rounded-lg bg-bikeexplogray-light p-2 w-full">
          <h1 className="text-white font-bold w-4/5 flex flex-row gap-2 text-xs md:text-base">
            {" "}
            <svg
              className="w-5 sm:w-6 h-5 sm:h-6"
              fill="none"
              stroke="salmon"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
              ></path>
            </svg>
            Gestion des clients
          </h1>
          <div className="flex flex-row  border-salmon">
            <input
              value={wordEntered}
              className="appearance-none bg-transparent border-bikeexplogray-dark placeholder-bikeexplogray-dark text-white w-24 sm:w-52 lg:w-96  h-6 sm:h-8 rounded-lg focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 text-xs md:text-base"
              placeholder="Rechercher un client"
              type="text"
              onChange={(event) => handleSearch(event)}
            />
            <svg
              className="w-6 h-6 text-salmon flex items-center ml-1 hidden md:block"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
        </div>

        <div class="inline-block rounded-t-lg">
          <div className="rounded-lg bg-bikeexplogray-light p-3 text-white my-4">
            <p className="text-sm text-center">
              Si le client apparait en{" "}
              <span className="text-salmon">orange</span>, il a été créé par un
              administrateur et n'a pas encore créé son compte sur Bike Explo
              Pro
            </p>
          </div>
          <div className="w-full bg-salmon text-white text-center rounded-t-lg p-1 lg:p-2 flex items-center justify-center text-sm lg:text-base lg:font-semibold">
            Clients
            {isLoading && (
              <div
                class="spinner-border animate-spin inline-block w-5 ml-4 h-5 border-3 rounded-full text-white"
                role="status"
              >
                <span class="visually-hidden">Chargement...</span>
              </div>
            )}
          </div>
          {isSuccess && (
            <div className="bg-green-500 text-white text-center flex items-center justify-center text-xs lg:font-bold lg:text-sm">
              Client supprimé !
            </div>
          )}
          <table className="table-auto text-white w-full rounded-t-lg">
            <thead>
              <tr className="bg-bikeexplogray-light rounded-t-lg text-xs sm:text-sm lg:text-base">
                <th className="p-2">Nom de l'entreprise</th>

                <th className="p-2">Email</th>

                <th className="p-2">Téléphone</th>
                <th className="p-2 hidden sm:hidden lg:block">Codes restant</th>
                {/*  <th className="p-2">Adresse</th> */}
                {/*   <th className="p-2 hidden sm:hidden lg:block">Code postal</th> */}
                <th className="p-2">Ville</th>
                <th className="p-2">Site web</th>
                <th className="p-2">Modification</th>
                <th className="p-2">Suppression</th>
              </tr>{" "}
            </thead>
            <tbody>{currentPageData}</tbody>
          </table>
          <div className="bg-bikeexplogray-light rounded-b-lg p-2 flex items-center justify-center font-bold text-xs sm:text-sm lg:text-base">
            <ReactPaginate
              className="flex items-center h-full"
              previousLabel={
                <svg
                  className="lg:w-6 lg:h-6 w-4 h-4"
                  fill="none"
                  stroke="salmon"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  ></path>
                </svg>
              }
              nextLabel={
                <svg
                  className="lg:w-6 lg:h-6 w-4 h-4"
                  fill="none"
                  stroke="salmon"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  ></path>
                </svg>
              }
              pageCount={pageCount}
              onPageChange={handlePageClick}
              activeClassName={"bg-bikeexplogray-light"}
              pageClassName={
                "mx-5 bg-bikeexplogray-dark h-full w-12 flex items-center justify-center text-salmon"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
