import React, { useState, useEffect } from "react";
import BoutonModif from "../components/boutonmodifparcours";
import axios from "axios";
import { apiUrl } from "../utils/urls.js";

export default function ParcoursAdmin() {
  const [parcours, getParcours] = useState([]);

  useEffect(() => {
    var config = {
      method: "get",
      url: `${apiUrl}/api/trips/unpublished`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        getParcours(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div className="flex flex-row flex-nowrap overflow-x-auto sm:w-99 lg:w-192 gap-4 w-44">
      {parcours?.map((value) => {
        return (
          <a key={value.id} href={`parcours/${value.id}`}>
            <div className="bg-salmon text-white w-20 h-28 lg:w-32 lg:h-48 rounded-lg z-0 relative">
              {value && value?.pictures[0] !== undefined && (
                <img
                  src={`${apiUrl}${value.pictures[0].path}`}
                  alt="vignette"
                  className="w-20 h-28 lg:w-32 lg:h-48 rounded-lg z-20 absolute"
                />
              )}
              <BoutonModif className="relative z-30" />
              <div className="z-10 relative p-1 text-xs lg:text-base">
                {value.title}
              </div>
            </div>
          </a>
        );
      })}
    </div>
  );
}
