import React, { useState, useEffect } from "react";
import SideBarAdmin from "../components/sidenavbar";
import jwt_decode from "jwt-decode";
import axios from "axios";
import UpdateProfil from "../components/updateProfilAdmin";
import { apiUrl } from "../utils/urls.js";

export default function ProfilAdmin() {
  if (localStorage.getItem("token")) {
    var tok = localStorage.getItem("token");
    var decoded = jwt_decode(tok);
    var Id = decoded.id;
  }

  const [admin, getAdmin] = useState("");

  useEffect(() => {
    var config = {
      method: "get",
      url: `${apiUrl}/api/admins/${Id}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        getAdmin(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    if (!admin) return "Vous n'êtes pas connecté.";
  }, [Id, admin]);

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-2 lg:gap-4 w-full h-full m-2 lg:m-4">
        <h1 className="rounded-lg bg-bikeexplogray-light text-white font-bold p-2 px-3 flex flex-row gap-2 h-12 items-center text-xs md:text-base">
          <svg
            className="w-5 sm:w-6 h-5 sm:h-6"
            fill="none"
            stroke="salmon"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            ></path>
          </svg>{" "}
          Mon profil{" "}
        </h1>
        <div className="flex flex-row gap-2 lg:gap-4 w-full lg:my-4">
          <div className="w-1/3">
            <h2 className="rounded-t-lg text-center font-semibold text-white bg-salmon py-1 text-xs md:text-base">
              Mes infos
            </h2>
            <div className="rounded-b-lg bg-bikeexplogray-light p-3 text-white text-xs md:text-base">
              <div>
                <label className="font-semibold">Nom d'utilisateur : </label>
                {admin.username}
              </div>
              <div>
                <label className="font-semibold">Email : </label>
                {admin.email}
              </div>
              <div className="text-center">
                {" "}
                <button className="rounded-full bg-salmon px-2 lg:px-8 lg:py-1 text-center mt-2 lg:mt-4 shadow-xl border-2 border-salmon hover:bg-white hover:text-salmon">
                  <UpdateProfil />
                </button>
              </div>
            </div>
          </div>
          <div className="w-2/3 hidden">
            <h2 className="text-white text-center font-semibold bg-salmon py-1 rounded-t-lg text-xs md:text-base">
              {" "}
              Mes brouillons
            </h2>
            <div className="rounded-b-lg bg-bikeexplogray-light p-3 text-white">
              <div></div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4 hidden">
          <div className="w-1/2">
            <h2 className="rounded-t-lg text-center font-semibold text-white bg-salmon py-1 px-2 text-xs md:text-base">
              Mes parcours publiés
            </h2>
            <div className="rounded-b-lg bg-bikeexplogray-light p-3 text-white">
              <div>{admin.trips}</div>
            </div>
          </div>
          <div className="w-1/2">
            <h2 className="rounded-t-lg text-center font-semibold text-white bg-salmon py-1 text-xs md:text-base">
              Mes articles publiés
            </h2>
            <div className="rounded-b-lg bg-bikeexplogray-light p-3 text-white">
              <div>{admin.articles}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
