import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../utils/urls.js";
// REACT QUERY
import { useQuery, useMutation, useQueryClient } from "react-query";

// IMPORT COMPONENTS
import SideBarAdmin from "../components/sidenavbar";
import UploadArticle from "../components/uploadArticle";

export default function AddImageToPoi() {
  let { id } = useParams();

  const [popup, setPopup] = useState(false);
  //const [article, setArticle] = useState({});

  const queryKey = ["blog"];
  const getArticle = async () => {
    const { data } = await axios.get(
      `${apiUrl}/api/articles/${id}`
    );
    return data;
  };

  const useArticle = () => useQuery(queryKey, getArticle);
  const {
    data: article,
    isSuccess: articleIsSuccess,
    isLoading: articleIsLoading,
    isError: articleIsError,
  } = useArticle();

  // DELETE PICTURE
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    async (pictureid) => {
      let url = `${apiUrl}/api/pictures/${pictureid}`;
      await axios
        .delete(url)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return (
    <div className="bg-bikeexplogray-dark flex flex-row gap-2 md:gap-4 w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-2 md:gap-4 w-full h-screen overflow-y-auto overflow-x-auto m-2 sm:m-4">
        <div className="flex flex-row justify-between w-full items-center bg-bikeexplogray-light text-white rounded-lg p-2 h-12">
          <h2 className="ml-2 md:ml-3 font-bold text-xs md:text-base">
            Créer un article : étape 2 - Ajouter une image
          </h2>

          <svg
            className="w-5 h-5 md:w-6 md:h-6 md:mr-5 mr-3"
            fill="salmon"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
            <path
              fillRule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <div className="flex flex-col ijustify-center items-center gap-4 my-4">
          <div className="md:w-1/2 flex flex-col gap-2">
            {articleIsSuccess && article?.pictures[0] !== undefined && (
              <img
                src={`${apiUrl}${article.pictures[0].path}`}
                alt="vignette du parcours"
                className="rounded-lg bg-salmon object-contain"
              />
            )}
            {articleIsSuccess && article?.pictures[0] === undefined && (
              <img
                src={``}
                alt="vignette du parcours"
                className="rounded-lg bg-salmon h-60"
              />
            )}

            {articleIsSuccess && article?.pictures[0] !== undefined && (
              <button
                className="text-white rounded-full p-1 bg-salmon border-2 border-salmon hover:bg-white hover:text-salmon text-xs md:text-base"
                type="button"
                onClick={(e) => {
                  mutate(article.pictures[0].id);
                  setPopup(true);
                  setTimeout(() => setPopup(false), 2000);
                }}
              >
                Supprimer une image
              </button>
            )}
            {isSuccess && popup && (
              <div className="text-white rounded-full p-1 bg-green-500 border-2 text-center border-green-500 ext-xs md:text-base">
                Image supprimée
              </div>
            )}

            {articleIsSuccess && article?.pictures[0] === undefined && (
              <UploadArticle articleId={id} />
            )}
          </div>

          <a
            className="my-4 flex items-center justify-center h-8 sm:h-10 w-2/6 sm:w-1/6 font-semibold text-salmon border-salmon rounded-full border-2 border-bikeexplogray-light hover:bg-white hover:text-salmon hover:border-salmon hover:border-1 text-xs md:text-base"
            href="/poi"
          >
            Terminer
          </a>
        </div>
      </div>
    </div>
  );
}
