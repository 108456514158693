import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../utils/urls.js";

//COMPONENTS
import SideBarAdmin from "../../components/sidenavbar";
import UploadLogo from "../../components/uploadLogo";

// REACT QUERY
import { useQuery, useMutation, useQueryClient } from "react-query";

export default function ProfilClient({ data }) {
  const [formData, setFormData] = useState({});
  const [updateClient, setUpdateClient] = useState(data);
  const [clientInfo, setClientInfo] = useState({});
  const [confirm, setConfirm] = useState({});
  const [popup, setPopup] = useState(false);

  let { id } = useParams();

  const queryKey = ["client"];
  const getClients = async () => {
    const { data } = await axios.get(
      `${apiUrl}/api/clients/${id}`
    );
    return data;
  };

  const useClient = () => useQuery(queryKey, getClients);
  const {
    data: client,
    isSuccess: clientIsSuccess,
    isLoading: clientIsLoading,
    isError: clientIsError,
  } = useClient();

  // DELETE PICTURE
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    async (pictureid) => {
      let url = `${apiUrl}/api/pictures/${pictureid}`;
      await axios
        .delete(url)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    {
      onSuccess: () => {
        queryClient?.invalidateQueries(queryKey);
      },
    }
  );

  async function update(e) {
    e.preventDefault();
    setUpdateClient({ ...updateClient, formData });

    var axios = require("axios");
    var data = formData;
    var config = {
      method: "put",
      url: `${apiUrl}/api/clients/` + id,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        const mess = "Le compte client a bien été modifié";

        setConfirm({ ...confirm, mess });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    const fetchClient = async () => {
      const client = await axios(`${apiUrl}/api/clients/${id}`);
      setClientInfo(client.data);
    };
    fetchClient();
  }, [formData]);

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-2 w-full h-screen overflow-y-auto m-2">
        <h1 className="rounded-lg bg-bikeexplogray-light text-white font-bold p-2 px-3 flex flex-row gap-2 h-10 sm:h-12 items-center text-xs md:text-base">
          <svg
            className="w-5 sm:w-6 h-5 sm:h-6"
            fill="none"
            stroke="salmon"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            ></path>
          </svg>{" "}
          Profil client : {clientInfo.storeName}
        </h1>{" "}
        <div className="bg-green-500 rounded-lg text-white text-center text-xs w-full sm:text-base">
          {" "}
          {confirm.mess}
        </div>
        <form
          className="self-center w-full md:w-2/3 mb-10 flex flex-col gap-6"
          //enctype="multipart/form-data"
          onSubmit={update}
        >
          <div className="rounded-md shadow-sm -space-y-px flex flex-col gap-4">
            <div>
              <label htmlFor="store-name" className="text-white">
                Nom du magasin
              </label>
              <input
                id="store-name"
                name="store-name"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder={clientInfo.storeName}
                onChange={(e) =>
                  setFormData({ ...formData, storeName: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col">
              <h2 className="rounded-t-lg text-center font-semibold text-white bg-salmon py-1 text-xs md:text-base">
                Logo
              </h2>
              <div className="rounded-b-lg bg-bikeexplogray-light p-3 text-white flex flex-col justify-center">
                {client?.pictures[0] !== undefined && (
                  <img
                    src={`${apiUrl}${client.pictures[0].path}`}
                    alt="vignette du parcours"
                    className="rounded-lg h-32 object-contain w-auto my-2"
                  />
                )}
                {client?.pictures[0] === undefined && (
                  <UploadLogo clientId={id} />
                )}

                {client?.pictures[0] !== undefined && (
                  <div className="text-center">
                    <button
                      className="text-white rounded-full p-1 sm:w-1/2 bg-salmon border-2 border-salmon hover:bg-white hover:text-salmon text-xs md:text-base"
                      type="button"
                      onClick={(e) => {
                        mutate(client.pictures[0].id);
                        setPopup(true);
                        setTimeout(() => setPopup(false), 2000);
                      }}
                    >
                      Supprimer une image
                    </button>
                  </div>
                )}
                {isSuccess && popup && (
                  <div className="text-white my-2 rounded-full bg-green-500 border-2 text-center border-green-500 text-xs">
                    Logo supprimé
                  </div>
                )}
              </div>
            </div>
            <div className="hidden">
              <label htmlFor="username" className="text-white">
                Nom d'utilisateur
              </label>
              <input
                id="username"
                name="username"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white rounded-lg focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder={clientInfo.username}
                onChange={(e) =>
                  setFormData({ ...formData, username: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="email-address" className="text-white">
                Email
              </label>
              <input
                id="email-address"
                name="email"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder={clientInfo.email}
                /* onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                } */
              />
            </div>
            <div>
              <label htmlFor="phone-number" className="text-white">
                Numéro de téléphone
              </label>
              <input
                id="phone-number"
                name="phone"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder={clientInfo.phone}
                /* onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                } */
              />
            </div>
            <div>
              <label htmlFor="website" className="text-white">
                Site web
              </label>
              <input
                id="website"
                name="website"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder={clientInfo.website}
                onChange={(e) =>
                  setFormData({ ...formData, website: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="booking link" className="text-white">
                Lien de réservation
              </label>
              <input
                id="booking-link"
                name="booking-link"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder={clientInfo.bookingLink}
                onChange={(e) =>
                  setFormData({ ...formData, bookingLink: e.target.value })
                }
              />
            </div>
            <div className="hidden">
              <label htmlFor="description" className="text-white">
                Description
              </label>
              <input
                id="description"
                name="description"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder={clientInfo.description}
                /*  onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                } */
              />
            </div>
            <div>
              <label htmlFor="address" className="text-white">
                Adresse
              </label>
              <input
                id="address"
                name="address"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder={clientInfo.adress}
                onChange={(e) =>
                  setFormData({ ...formData, adress: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="city" className="text-white">
                Ville
              </label>
              <input
                id="city"
                name="city"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder={clientInfo.city}
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="zipcode" className="text-white">
                Code postal
              </label>
              <input
                id="zipcode"
                name="zipcode"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder={clientInfo.zipcode}
                onChange={(e) =>
                  setFormData({ ...formData, zipcode: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="code" className="text-white">
                Nombre de codes restants
              </label>
              <input
                id="code"
                name="code"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder={clientInfo.credit}
                onChange={(e) =>
                  setFormData({ ...formData, credit: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="siret" className="text-white">
                Numéro SIRET
              </label>
              <input
                id="siret"
                name="siret"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder={clientInfo.siret}
                /* onChange={(e) =>
                  setFormData({ ...formData, siret: e.target.value })
                } */
              />
            </div>
            <div>
              <label htmlFor="TVA" className="text-white">
                Numéro TVA
              </label>
              <input
                id="TVA"
                name="TVA"
                className="text-xs md:text-base bg-bikeexplogray-light appearance-none rounded-lg relative block w-full px-3 py-2 border border-salmon placeholder-gray-300 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                placeholder={clientInfo.TVA}
                /* onChange={(e) =>
                  setFormData({ ...formData, TVA: e.target.value })
                } */
              />
            </div>
          </div>

          <button
            type="submit"
            className="text-xs md:text-base w-full p-1 bg-salmon text-sm font-medium rounded-lg text-white border-2 border-salmon hover:bg-white hover:text-salmon focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-salmon"
          >
            Modifier
          </button>
        </form>
      </div>
    </div>
  );
}
