import React, { useState, useEffect } from "react";
import SideBarAdmin from "../components/sidenavbar";
import axios from "axios";
import UploadArticle from "../components/uploadArticle";
import { apiUrl } from "../utils/urls.js";

// ERRORS GESTION
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function ParcoursAdmin({ data }) {
  // ERRORS VALIDATIONS
  let schema = yup.object().shape({
    title: yup.string().required("Le titre de l'article est requis !"),
    content: yup.string().required("La description de l'article est requise !"),
  });

  // ERRORS VARS
  const {
    register,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  console.log(errors);

  // STATES
  const [formData, setFormData] = useState({});
  const [article, setArticle] = useState(data);
  const [confirm, setConfirm] = useState({});
  const [showButton, setShowButton] = useState(false);
  const [hideButton, setHideButton] = useState(true);
  const [error, setError] = useState({});

  //upload files
  /*   const [name, setName] = useState();
  const [file, setFile] = useState(); */

  /*   const send = (event) => {
    const data = new FormData();
    data.append("name", name);
    data.append("file", file);

    axios
      .post("https://api.bikeexplo.com/api/upload/upload", data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }; */

  //scrolling menu select categories
  const [allCategoriesArticles, getCategoriesArticles] = useState([]);

  //scrolling menu select tags
  const [allTagsArticles, getTagsArticles] = useState([]);

  function onChangeHandler() {
    // GET SELECT VALUE
    if (document.getElementById("categorie") != null) {
      let selectvalue = document.getElementById("categorie").value;
      console.log(selectvalue);

      // GET ID ON SELECT VALUE
      let selectid = selectvalue.split(" ");
      console.log(selectid);
      console.log("id:", selectid[0]);
      return selectid[0];
    }
  }

  let selectedValue = onChangeHandler();

  function addCategoryToArticle() {
    const ID = article.id;

    var data = JSON.stringify({
      articles_id: ID,
      categoriesArticle_id: selectedValue,
    });

    var config = {
      method: "post",
      url: `${apiUrl}/api/category_articles`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }

  function getSelectedTag() {
    // GET SELECT VALUE
    if (document.getElementById("tag") != null) {
      let selecttag = document.getElementById("tag").value;

      // GET ID ON SELECT VALUE
      let selecttagid = selecttag.split(" ");
      return selecttagid[0];
    }
  }

  //associate tag to article
  function addTagsToArticle() {
    const ID = article.id;
    let selectedTag = getSelectedTag();

    var data = JSON.stringify({
      articles_id: ID,
      tags_id: selectedTag,
    });

    var config = {
      method: "post",
      url: `${apiUrl}/api/tag_articles`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }

  function AddTagsandArticles() {
    addCategoryToArticle();
    addTagsToArticle();
    const ID = article.id;
    //confirmation message
    const mess =
      "Vous allez maintenant passer à la deuxième étape de création d'un article";

    setConfirm({ ...confirm, mess });
    setTimeout(function () {
      // after 2 seconds
      document.getElementById("confirmmessage").style.visibility = "hidden";
    }, 2000);
    setTimeout(function () {
      // after 2 seconds
      document.location.pathname = "/createarticle2/" + ID;
    }, 2000);
  }

  async function createArticle(e) {
    e.preventDefault();
    setArticle({ ...article, formData });

    data = formData;

    axios
      .post(`${apiUrl}/api/articles`, data)
      .then(function (response) {
        setArticle(response.data);
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "L'article n'a pas pu être créé en raison d'une erreur. Veuillez bien vérifier que les textes ne contiennent pas de caractères spéciaux.";

        setError({ ...error, message });

        setTimeout(function () {
          window.location.reload();
        }, 3000);
      });

    setShowButton(true);
    setHideButton(false);
  }

  //handle checkbox
  async function handleCheckBoxPublished() {
    if (document.getElementById("published").checked === true) {
      await setFormData({ ...formData, published: false });
    } else await setFormData({ ...formData, published: true });
  }

  //Show category list
  useEffect(() => {
    axios
      .get(`${apiUrl}/api/categoryarticles`)
      .then(function (response) {
        getCategoriesArticles(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });

    axios
      .get(`${apiUrl}/api/tagsarticle`)
      .then(function (response) {
        getTagsArticles(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, []);

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-4 m-2 sm:m-4 w-full h-screen overflow-y-auto">
        <h1 className="text-white font-bold rounded-lg bg-bikeexplogray-light p-2 flex flex-row justify-between h-12 items-center text-xs md:text-base">
          Créer un nouvel article{" "}
          <svg
            className="w-5 h-5 mr-3 lg:w-6 lg:h-6 lg:mr-5"
            fill="salmon"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
            <path
              fillRule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clipRule="evenodd"
            ></path>
          </svg>
        </h1>
        <div
          id="confirmmessage"
          class="rounded-full bg-green-400 text-white text-center w-full text-xs md:text-base"
        >
          {" "}
          {confirm.mess}{" "}
        </div>
        <div className="flex flex-row gap-5">
          {/* Bloc 1 image, titre, contenu, validation */}
          <div className="flex flex-col w-2/3 sm:w-4/5 gap-3">
            <div className="flex flex-col gap-3">
              <div className="rounded-lg bg-bikeexplogray-light flex flex-col sm:flex-row p-2 h-12">
                <label className="text-salmon w-1/5 text-xs md:text-base">
                  Titre
                </label>
                <input
                  id="titre"
                  {...register("title")}
                  className={
                    errors.distance
                      ? "self-start w-4/5 appearance-none bg-bikeexplogray-light text-white border-red-500 placeholder-gray-400 text-xs md:text-base  focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 "
                      : "self-start w-4/5 appearance-none bg-bikeexplogray-light text-white border-bikeexplogray-light placeholder-gray-400 text-xs md:text-base  focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 "
                  }
                  placeholder="Titre de l'article"
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                />
                {errors.title && (
                  <p className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.title?.message}
                  </p>
                )}
              </div>
              <div className="rounded-lg bg-bikeexplogray-light flex flex-col sm:flex-row p-2 h-96">
                <label className="text-salmon w-1/5 text-xs md:text-base">
                  {" "}
                  Contenu{" "}
                </label>
                <textarea
                  id="content"
                  {...register("content")}
                  className={
                    errors.distance
                      ? "md:ml-6 self-start w-4/5 appearance-none bg-bikeexplogray-light text-white border-red-500 placeholder-gray-400 text-xs md:text-base w-full focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 "
                      : "md:ml-6 self-start w-4/5 appearance-none bg-bikeexplogray-light text-white border-bikeexplogray-light placeholder-gray-400 text-xs md:text-base w-full focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 "
                  }
                  placeholder="Contenu de l'article"
                  onChange={(e) =>
                    setFormData({ ...formData, content: e.target.value })
                  }
                />

                {errors.content && (
                  <p className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.content?.message}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Bloc 2 : Catégories, Tags, brouillon */}
          <div className="flex flex-col w-1/3 lg:w-1/5 gap-3">
            <div>
              <h1 className="bg-salmon text-white rounded-t-lg p-1 text-center text-xs md:text-base">
                Catégories
              </h1>
              <div className="self-center flex flex-row gap-2 bg-bikeexplogray-light rounded-b-lg p-2 text-xs md:text-base">
                <select
                  type="text"
                  placeholder="Catégorie"
                  id="categorie"
                  onChange={onChangeHandler}
                  className="w-full appearance-none rounded-lg bg-bikeexplogray-dark text-bikeexplogray-light text-xs md:text-base"
                >
                  <option className="text-xs md:text-sm">
                    Ajouter une catégorie
                  </option>

                  {allCategoriesArticles.map((catart) => {
                    return (
                      <option key={catart.id} value={catart.id}>
                        {catart.id} {catart.category}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="">
              <h1 className="bg-salmon text-white rounded-t-lg p-1 text-center text-xs md:text-base">
                Tags
              </h1>
              <div className="self-center flex flex-row gap-2 bg-bikeexplogray-light rounded-b-lg p-2 text-xs md:text-base">
                <select
                  type="text"
                  placeholder="Catégorie"
                  id="tag"
                  onChange={getSelectedTag}
                  className="w-full appearance-none rounded-lg bg-bikeexplogray-dark text-bikeexplogray-light text-xs md:text-base"
                >
                  <option className="text-xs md:text-sm">Ajouter un tag</option>

                  {allTagsArticles.map((tagart) => {
                    return (
                      <option key={tagart.id} value={tagart.id}>
                        {tagart.id} {tagart.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="flex flex-row justify-between gap-2 bg-bikeexplogray-light rounded-lg p-2 text-xs md:text-base">
              <label className="text-white">Brouillon</label>
              <input
                id="published"
                name="published"
                type="checkbox"
                defaultChecked={true}
                className="appearance-none mr-2 rounded-full focus:ring-salmon"
                onClick={handleCheckBoxPublished}
              />
            </div>
          </div>
        </div>{" "}
        {!isValid && (
          <p className="text-center font-medium tracking-wide text-red-500 text-xs">
            Formulaire non valide, veuillez remplir tous les champs !
          </p>
        )}
        <div class="bg-red-600 rounded-lg text-white text-xs sm:text-base text-center w-full ">
          {" "}
          {error.message}{" "}
        </div>
        <div className="flex flex-row gap-5 self-center mb-8 lg:my-4 w-full justify-center">
          {hideButton ? (
            <button
              className="bg-salmon rounded-full p-2 text-white border-2 border-salmon hover:text-salmon hover:bg-white text-xs md:text-base w-1/4"
              onClick={createArticle}
              //disabled={isSubmitting || !isValid}
            >
              Créer l'article
            </button>
          ) : null}
          {showButton ? (
            <button
              className="text-salmon rounded-full p-2 border-2 border-salmon hover:text-salmon hover:bg-white text-xs md:text-base w-1/4"
              onClick={AddTagsandArticles}
            >
              Valider et passer à l'étape 2
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
