import React from "react";

export default function ModifParcours() {
  return (
    <div>
      <button className="rounded-full bg-bikeexplogray-light flex p-1">
        <svg
          className="w-4 h-4 lg:w-5 lg:h-5 text-salmon"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          ></path>
        </svg>
      </button>
    </div>
  );
}
