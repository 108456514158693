import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../utils/urls.js";

// IMPORT COMPONENTS
import SideBarAdmin from "../components/sidenavbar";
import UploadPoi from "../components/uploadPoi";

// REACT QUERY
import { useQuery, useMutation, useQueryClient } from "react-query";
import Carousel from "../components/carousel";

export default function AddImageToPoi() {
  let { id } = useParams();

  const queryKey = ["poi"];
  const getTrip = async () => {
    const { data } = await axios.get(
      `${apiUrl}/api/pointsOfInterest/${id}`
    );
    return data;
  };

  const useTrip = () => useQuery(queryKey, getTrip);
  const {
    data: poi,
    isSuccess: poiIsSuccess,
    isLoading: poiIsLoading,
    isError: poiIsError,
  } = useTrip();

  // DELETE PICTURE
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    async (pictureid) => {
      let url = `${apiUrl}/api/pictures/${pictureid}`;
      await axios
        .delete(url)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  console.log(poi?.pictures.length);

  return (
    <div className="bg-bikeexplogray-dark flex flex-row gap-2 md:gap-4 w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-2 md:gap-4 w-full h-screen overflow-y-auto overflow-x-auto m-2 sm:m-4">
        <div className="flex flex-row justify-between w-full items-center bg-bikeexplogray-light text-white rounded-lg p-2 h-12">
          <h2 className="ml-2 md:ml-3 font-bold text-xs md:text-base">
            Créer Point d'intérêt : étape 2 - Ajouter une image
          </h2>

          <svg
            className="w-5 h-5 md:w-6 md:h-6 md:mr-5 mr-3"
            fill="salmon"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
            <path
              fillRule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <div className="flex flex-col ijustify-center items-center gap-4">
          <div className="md:w-1/2 flex flex-col gap-2">
            {poiIsSuccess &&
              poi?.pictures[0] !== undefined &&
              poi?.pictures.length <= 1 && (
                <img
                  src={`${apiUrl}${poi.pictures[0].path}`}
                  alt="vignette du parcours"
                  className="rounded-lg bg-salmon h-96"
                />
              )}
            {poiIsSuccess && poi?.pictures[0] === undefined && (
              <img
                src={``}
                alt="vignette du parcours"
                className="rounded-lg bg-salmon h-96"
              />
            )}
            {poiIsSuccess &&
              poi?.pictures[0] !== undefined &&
              poi?.pictures.length >= 2 && (
                <Carousel images={poi.pictures} height={90} />
              )}
            {poiIsSuccess && <UploadPoi PointOfInterestId={id} />}
            {poiIsSuccess && poi?.pictures[0] !== undefined && (
              <div>
                <h2 className="text-white bg-salmon rounded-t-lg w-full h-9 text-center items-center flex justify-center text-xs md:text-base">
                  Images du point d'intérêt
                </h2>
                {isSuccess && (
                  <div className="bg-green-500 text-white text-center flex items-center justify-center text-xs lg:font-bold lg:text-sm">
                    Image supprimée !
                  </div>
                )}
                <table className="bg-bikeexplogray-light w-full text-xs md:text-base">
                  <thead>
                    <tr className="text-salmon text-xs sm:text-sm lg:text-base bg-bikeexplogray-dark h-8 lg:h-10">
                      <th className="text-center">Image</th>
                      <th className="text-center">Supprimer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {poi.pictures?.map((picture, index) => {
                      return (
                        <tr
                          className="text-white even:bg-bikeexplogray-dark"
                          key={picture.id}
                        >
                          <td className="text-center my-5 mx-2 h-10">
                            <div className="text-start">
                              Image - {index + 1}
                            </div>
                          </td>
                          <td className="text-center my-5 mx-2 h-10">
                            <button
                              onClick={(e) => {
                                mutate(picture.id);
                              }}
                            >
                              <svg
                                id="deleteBtn"
                                className="w-4 h-4 ml-2 lg:w-6 lg:h-6 lg:ml-5"
                                fill="none"
                                stroke="salmon"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                ></path>
                              </svg>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="text-white bg-salmon rounded-b-lg w-full h-3 text-center items-center flex justify-center text-xs md:text-base"></div>
              </div>
            )}
          </div>
          <a
            className="flex items-center justify-center h-8 sm:h-10 w-2/6 sm:w-1/6 font-semibold text-salmon border-salmon rounded-full border-2 border-bikeexplogray-light hover:bg-white hover:text-salmon hover:border-salmon hover:border-1 text-xs md:text-base"
            href="/poi"
          >
            Terminer
          </a>
        </div>
      </div>
    </div>
  );
}
