import React, { useState } from "react";
import SideBarAdmin from "../components/sidenavbar";
import axios from "axios";
import Upload from "../components/upload";
import { apiUrl } from "../utils/urls.js";

// ERRORS GESTION
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function ParcoursAdmin({ data }) {
  // ERRORS VALIDATIONS
  let schema = yup.object().shape({
    title: yup.string().required("Le titre du parcours est requis !"),
    description: yup
      .string()
      .required("La description du parcours est requise !"),
    duration: yup
      .number()
      .typeError("La durée du parcours ne doit que comporter que des chiffres")
      .positive("La durée du parcours doit uniquement être positive"),
    elevation: yup
      .number()
      .typeError("Le dénivelé du parcours ne doit comporter que des chiffres")
      .positive("Le dénivelé du parcours doit uniquement être positif !"),
    distance: yup
      .number()
      .typeError("La distance du parcours ne doit comporter que des chiffres !")
      .positive("La distance du parcours doit uniquement être positive !"),
  });

  // ERRORS VARS
  const {
    register,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  // STATES
  const [formData, setFormData] = useState({});
  const [parcours, setParcours] = useState(data);
  const [selectedFile, setSelectedFile] = useState(null);
  const [confirm, setConfirm] = useState({});
  const [showButton, setShowButton] = useState(false);
  const [hideButton, setHideButton] = useState(true);
  const [error, setError] = useState({});

  //create trip
  const form = new FormData();

  // FUNCTION && STATE
  async function createParcours(e) {
    e.preventDefault();

    form.append("title", formData.title);
    form.append("description", formData.description);

    setParcours({ ...parcours, form });
    data = formData;
    axios
      .post(`${apiUrl}/api/trips`, data)
      .then(function (response) {
        setParcours(response.data);
        setSelectedFile(response.data);

        //confirmation message
        const mess = "Vos modifications ont bien été enregistrées";

        setConfirm({ ...confirm, mess });
        setTimeout(function () {
          // after 2 seconds
          document.getElementById("confirmmessage").style.visibility = "hidden";
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);

        const message =
          "Le parcours n'a pas pu être créé en raison d'une erreur. Veuillez bien vérifier que les textes ne contiennent pas de caractères spéciaux.";

        setError({ ...error, message });

        setTimeout(function () {
          window.location.reload();
        }, 3000);
      });

    setShowButton(true);
    setHideButton(false);
  }

  function NextStep() {
    const ID = parcours.id;

    //confirmation message
    const mess =
      "Vous allez maintenant passer à la deuxième étape de création du parcours";

    setConfirm({ ...confirm, mess });
    setTimeout(function () {
      // after 2 seconds
      document.getElementById("confirmmessage").style.visibility = "hidden";
    }, 3000);
    setTimeout(function () {
      // after 2 seconds
      document.location.pathname = "/creationparcours2/" + ID;
    }, 3000);
  }

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />

      <div className="flex flex-col gap-2 lg:gap-4 m-2 sm:m-4 w-full h-screen overflow-y-auto">
        <h1 className="text-white font-bold rounded-lg bg-bikeexplogray-light p-2 flex flex-row  justify-between gap-1 sm:gap-2 h-10 lg:h-12 items-center text-xs md:text-base">
          Créer un nouveau parcours : étape 1{" "}
          <svg
            class="w-5 h-5 text-salmon lg:w-6 lg:h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            ></path>
          </svg>
        </h1>
        <div
          id="confirmmessage"
          class="rounded-full bg-green-400 text-white text-center w-full text-xs md:text-base"
        >
          {" "}
          {confirm.mess}{" "}
        </div>
        <div className="flex flex-row gap-5 justify-end">
          {/* Partie 1: Titre et description */}
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-col sm:flex-row sm:gap-8 sm:h-1/6 text-xs sm:text-base w-full">
              <label className="text-white sm:w-1/5 text-left">Titre</label>
              <input
                id="titre"
                {...register("title")}
                className="appearance-none rounded-lg relative block w-full sm:w-4/5 h-8 md:h-10 px-3 py-2 bg-bikeexplogray-light border-bikeexplogray-light border-2 placeholder-gray-200 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 text-xs sm:text-base"
                placeholder="Titre du parcours"
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col sm:flex-row sm:gap-8 sm:h-4/6 text-xs sm:text-base w-full">
              <label className="text-white md:w-1/5 text-left">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                className="overflow-auto w-full md:w-4/5 px-3 py-2 h-36 sm:h-60 appearance-none bg-bikeexplogray-light border-bikeexplogray-light rounded-lg placeholder-gray-200 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 text-xs sm:text-base"
                placeholder="Description du parcours"
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col sm:flex-row sm:gap-8 sm:h-1/6 text-xs sm:text-base w-full md:mt-3">
              <label className="text-white sm:w-1/5 text-left">Ville</label>
              <input
                id="ville"
                {...register("ville")}
                className="appearance-none rounded-lg relative block w-full sm:w-4/5 h-8 md:h-10 px-3 py-2 bg-bikeexplogray-light border-bikeexplogray-light border-2 placeholder-gray-200 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 text-xs sm:text-base"
                placeholder="Ville principale du parcours"
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div class="bg-red-600 rounded-lg text-white text-xs sm:text-base text-center w-full ">
          {" "}
          {error.message}{" "}
        </div>
        {/* Partie 2 : Confirmation / Suppression */}
        <div className="flex flex-row gap-2 justify-center w-full my-4">
          {hideButton ? (
            <button
              className="bg-salmon rounded-full p-2 text-white w-1/2 border-2 border-bikeexplogray-dark active:bg-bikeexplogray-dark active:border-salmon hover:bg-white hover:border-salmon hover:text-salmon text-xs sm:text-base"
              onClick={createParcours}
              disabled={isSubmitting}
            >
              Valider
            </button>
          ) : null}
          {showButton ? (
            <button
              className="border-2 border-salmon text-salmon rounded-full hover:bg-white p-2 w-1/2 text-xs sm:text-base"
              onClick={NextStep}
            >
              Passer à l'étape 2
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
