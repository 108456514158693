import React, { useState, useEffect } from "react";
import SideBarAdmin from "../components/sidenavbar";
import Categories from "../components/categoriesTrips";
import BoutonModif from "../components/boutonmodifparcours";
import Brouillons from "../components/parcoursBrouillons";
import SearchBar from "../components/searchBar";
import axios from "axios";
import { apiUrl } from "../utils/urls.js";

export default function ParcoursAdmin() {
  const [allData, setAllData] = useState([]);
  const [allTrips, setAllTrips] = useState([]);

  useEffect(() => {
    axios(`${apiUrl}/api/trips/published`)
      .then((response) => {
        setAllData(response.data);
      })
      .catch((error) => {
        console.log("Une erreur est survenue lors de la recherche" + error);
      });

    axios(`${apiUrl}/api/trips`)
      .then((response) => {
        setAllTrips(response.data);
      })
      .catch((error) => {
        console.log("Une erreur est survenue lors de la recherche" + error);
      });
  }, []);

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-4 m-2 sm:m-4 overflow-y-auto">
        <div className="flex flex-row-reverse justify-between px-1 sm:px-2 rounded-lg bg-bikeexplogray-light w-full">
          <SearchBar
            placeholder="Rechercher un parcours"
            data={allTrips}
            link={"parcours/"}
          />

          <h1 className="text-white text-xs md:text-base font-bold self-center flex flex-row gap-2 items-center">
            {" "}
            <svg
              className="w-5 sm:w-6 h-5 sm:h-6"
              fill="salmon"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                clipRule="evenodd"
              ></path>
            </svg>
            Tous les parcours
          </h1>
        </div>
        <div className="flex flex-col gap-2 sm:gap-4">
          <h1 className="text-white text-xs md:text-base font-bold">
            Catégories
          </h1>
          <Categories />
        </div>
        <div className="flex flex-row gap-4">
          <div className="flex flex-col">
            <h2 className="text-white font-semibold my-4 text-xs md:text-base">
              Nouveau
            </h2>
            <a
              className="rounded-lg bg-salmon text-center text-white font-semibold p-2 w-20 h-28 lg:w-32 lg:h-48 text-xs lg:text-base hover:bg-hoversalmon"
              href="/creationparcours"
            >
              + Créer un nouveau parcours
            </a>
          </div>
          <div className="flex flex-col">
            <h2 className="text-white font-semibold my-4 text-xs md:text-base">
              Brouillons
            </h2>
            <Brouillons />
          </div>
        </div>
        <div className="">
          <h2 className="text-white font-semibold my-4 text-xs md:text-base">
            Les Parcours publiés
          </h2>

          <div className="flex flex-row flex-wrap overflow-y-auto gap-x-3 gap-y-6 w-72 lg:w-193 sm:w-full lg:h-84 relative">
            {allData?.map((value, index) => {
              return (
                <a key={value.id} href={`parcours/${value.id}`}>
                  <div className="bg-salmon text-white w-20 h-28 lg:w-32 lg:h-48 rounded-lg z-0 relative">
                    {value && value?.pictures[0] !== undefined && (
                      <img
                        src={`${apiUrl}${value.pictures[0].path}`}
                        alt="vignette"
                        className="absolute z-20 w-20 h-28 lg:w-32 lg:h-48 rounded-lg"
                      />
                    )}
                    <BoutonModif className="relative z-30" />
                    <div className="relative z-10 p-1 text-xs lg:text-base">
                      {value.title}
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
