import React, { useState } from "react";
import SideBarAdmin from "../components/sidenavbar";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { apiUrl } from "../utils/urls.js";

export default function ProfilAdmin() {
  const [keys, getKeys] = useState([]);
  const [filteredData, setFilteredData] = useState(keys);
  const [wordEntered, setWordEntered] = useState("");

  const queryKey = ["filteredData"];
  const { isLoading, data } = useQuery(queryKey, () => loadTrips());
  const queryClient = useQueryClient();

  // PAGINATION
  const PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * PER_PAGE;
  const totalclients = filteredData?.length;
  const pageCount = Math.ceil(totalclients / PER_PAGE);

  const currentPageData = filteredData
    ?.slice(offset, offset + PER_PAGE)
    .map((item) => {
      return (
        <tr
          className="text-white even:bg-bikeexplogray-dark text-xs sm:text-sm lg:text-base"
          key={item.id}
        >
          <td className="text-center my-5 mx-2 h-10">{item?.title}</td>
          <td className="text-center my-5 mx-2 h-10">
            {item?.client?.storeName}
          </td>
          <td className="text-center my-5 mx-2 h-10">{item?.code_counter}</td>
        </tr>
      );
    });

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  function loadTrips() {
    axios
      .get(`${apiUrl}/api/trips/`)
      .then((response) => {
        getKeys(response.data);
        setFilteredData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSearch = (event) => {
    const searchWord = event.target.value;

    setWordEntered(searchWord);

    const newFilter = keys.filter((value) => {
      return value.client?.storeName
        .toLowerCase()
        .includes(searchWord.toLowerCase());
    });

    setFilteredData(newFilter);
  };

  return (
    <div className="flex flex-row gap-1 sm:gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-4 m-2 sm:m-4 w-full h-screen overflow-y-auto">
        <div className="flex flex-row justify-between items-center rounded-lg bg-bikeexplogray-light p-2 w-full">
          <h1 className="text-white font-bold w-4/5 flex flex-row gap-2 text-xs md:text-base">
            <svg
              className="w-5 sm:w-6 h-5 sm:h-6"
              fill="none"
              stroke="salmon"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
              ></path>
            </svg>
            Chiffres clés{" "}
          </h1>
          <div className="flex flex-row w-2/5">
            <input
              value={wordEntered}
              className="appearance-none bg-transparent border-bikeexplogray-dark placeholder-bikeexplogray-dark text-white w-24 sm:w-52 lg:w-96  h-6 sm:h-8 rounded-lg focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 text-xs md:text-base"
              placeholder="Rechercher un parcours"
              type="text"
              onChange={(event) => handleSearch(event)}
            />
            <svg
              className="w-6 h-6 text-salmon flex items-center ml-1 hidden md:block"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
        </div>

        <div className="flex flex-col my-7">
          <h2 className="bg-salmon text-white text-center rounded-t-lg p-1 lg:p-2 flex items-center justify-center text-sm lg:text-base lg:font-semibold">
            Suivi des utilisations des parcours
          </h2>
          <table className="bg-bikeexplogray-light w-full">
            <thead>
              <tr className="bg-bikeexplogray-dark text-salmon text-xs sm:text-sm lg:text-base">
                <th className="text-center lg:my-5 lg:mx-2 lg:h-10">
                  Titre du parcours
                </th>
                <th className="text-center lg:my-5 lg:mx-2 lg:h-10">Client</th>
                <th className="text-center lg:my-5 lg:mx-2 lg:h-10">
                  Nombre de codes déclenchés
                </th>
              </tr>
            </thead>
            <tbody>{currentPageData}</tbody>
          </table>
          <div className="bg-bikeexplogray-light rounded-b-lg p-2 flex items-center justify-center font-bold text-xs sm:text-sm lg:text-base">
            <ReactPaginate
              className="flex  items-center h-full"
              previousLabel={
                <svg
                  className="lg:w-6 lg:h-6 w-4 h-4"
                  fill="none"
                  stroke="salmon"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  ></path>
                </svg>
              }
              nextLabel={
                <svg
                  className="lg:w-6 lg:h-6 w-4 h-4"
                  fill="none"
                  stroke="salmon"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  ></path>
                </svg>
              }
              pageCount={pageCount}
              onPageChange={handlePageClick}
              activeClassName={"bg-bikeexplogray-light"}
              pageClassName={
                "mx-5 bg-bikeexplogray-dark h-full w-12 flex items-center justify-center text-salmon"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
