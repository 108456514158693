import React from "react";
import { useQuery } from "react-query";
import { RefreshIcon } from "@heroicons/react/solid";
import axios from "axios";
import { apiUrl } from "../../utils/urls.js";

//COMPONENTS
import UpdateFAQ from "./updateFaq";
import DeleteFAQ from "./deleteFaq";

export default function Faq() {
  async function getFAQ() {
    const { data } = await axios
      .get(`${apiUrl}/api/faqs`)
      .catch(function (error) {
        console.log(error);
        throw new Error(
          `Une erreur est survenue lors de la récupération de la FAQ`
        );
      });
    return data;
  }

  const useFAQ = () => useQuery("FAQ", getFAQ);
  const { data: FAQ, isSuccess, isLoading, isError, error } = useFAQ();

  return (
    <div className=" flex flex-col gap-2">
      <h1 className="text-salmon font-bold text-base">
        Toutes les questions/réponses
      </h1>
      {isLoading && (
        <RefreshIcon className="w-10 h-10 animate-spin mx-auto text-salmon"></RefreshIcon>
      )}
      {isError && (
        <span className="text-red">
          {error.message ? error.message : error}
        </span>
      )}
      {!isLoading && !isError && FAQ && !FAQ.length && (
        <span className="text-red-400">La FAQ est vide</span>
      )}
      <div className="flex flex-row gap-2">
        {FAQ &&
          FAQ.length > 0 &&
          FAQ.map((faq, index) => (
            <div
              key={faq.id}
              className="bg-bikeexplogray-light rounded-lg w-1/3"
            >
              <div className="flex flex-row justify-end gap-1 p-1">
                <UpdateFAQ id={faq.id} />
                <DeleteFAQ id={faq.id} />
              </div>
              <div className="p-2">
                <h2 className="text-salmon font-medium">{faq.question}</h2>
                <p className="text-white">{faq.answer}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
