import React, { useState, useCallback, useEffect } from "react";
import "../views/Localisation/localisationAdmin.css";
import ReactMapGL, { FlyToInterpolator } from "react-map-gl";
import { useParams } from "react-router-dom";
import { apiUrl } from "../utils/urls.js";

// IMPORT COMPONENTS
import SideBarAdmin from "../components/sidenavbar";
import axios from "axios";
import LocalisationBox from "../components/LocalisationBox/localisationbox";
import "./form.css";
import UploadPoi from "../components/uploadPoi";
import Carousel from "../components/carousel";

// REACT QUERY
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationFormPoi from "../utils/validationFormPoi";
import "../index.css";

export default function EditPoi({ data }) {
  // VARS
  const mapToken =
    "pk.eyJ1IjoicGFyY291cnMtbWFwczEiLCJhIjoiY2x2YjBuM2dtMDNhNzJqcDc2ZWMwbXd0ayJ9.dgYngPnMcgiHigv4sexwXQ";
  const mapStyle = "mapbox://styles/mapbox/streets-v11";
  const [lng, setLng] = useState(5.36978);
  const [lat, setLat] = useState(43.296482);
  const [lngClick, setLngClick] = useState("");
  const [latClick, setLatClick] = useState("");
  const [zoom, setZoom] = useState(12.5);
  const [popup, setPopup] = useState(false);
  const [formData, setFormData] = useState({});
  const [viewport, setViewport] = React.useState({
    longitude: lng,
    latitude: lat,
    zoom: zoom,
    width: "100%",
    height: "30vh",
  });
  const [error, setError] = useState("");
  const { id } = useParams();

  const queryKey = ["poi"];
  const getTrip = async () => {
    const { data } = await axios.get(`${apiUrl}/api/pointsOfInterest/${id}`);
    return data;
  };

  const useTrip = () => useQuery(queryKey, getTrip);
  const {
    data: poi,
    isSuccess: poiIsSuccess,
    isLoading: poiIsLoading,
    isError: poiIsError,
  } = useTrip();

  // DELETE PICTURE
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    async (pictureid) => {
      let url = `${apiUrl}/api/pictures/${pictureid}`;
      await axios
        .delete(url)
        .then((res) => { })
        .catch((err) => {
          console.log(err);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  // ERRORS VARS
  const {
    register,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationFormPoi),
  });

  const onClick = useCallback((event) => {
    const lng = event.lngLat[0];
    const lat = event.lngLat[1];
    setLatClick(lat);
    setLngClick(lng);
  });

  useEffect(() => {
    setViewport({
      latitude: lat,
      longitude: lng,

      zoom: zoom,
      transitionInterpolator: new FlyToInterpolator({ speed: 2.0 }),
      transitionDuration: "auto",
      width: "100%",
      height: "30vh",
    });

    //Default values in input and textarea
    document.getElementById("title").defaultValue = poi?.title;
    document.getElementById("description").defaultValue = poi?.description;

    if (poi?.practicalInfo != null) {
      document.getElementById("practicalInfo").defaultValue =
        poi?.practicalInfo;
    }
    if (poi?.website !== null) {
      document.getElementById("website").defaultValue = poi?.website;
    }
    if (poi?.address !== null) {
      document.getElementById("address").defaultValue = poi?.address;
    }
    if (poi?.city !== null) {
      document.getElementById("city").defaultValue = poi?.city;
    }
    if (poi?.zipcode !== null) {
      document.getElementById("zipcode").defaultValue = poi?.zipcode;
    }
  }, [lat, lng, zoom, poi]);

  // EDIT POI
  const { mutate: editPoi, isSuccess: editPoiSuccess } = useMutation(
    async () => {
      let url = `${apiUrl}/api/pointsOfInterest/${id}`;

      data = formData;

      let coordonates = {
        latitude: latClick,
        longitude: lngClick,
      };

      if (latClick !== "" || lngClick !== "") {
        const finaldata = Object.assign(data, coordonates);

        await axios
          .put(url, finaldata)
          .then((res) => {
            //console.log(res.data);
            document.location.pathname = "/poi";
          })
          .catch((err) => {
            console.log(err);
            setError(
              "Une erreur s'est produite, veuillez replacer le point sur la carte pour que la modification puisse être effectuée."
            );
          });
      }

      const finaldata = data;
      console.log("FINAL DATA " + JSON.stringify(finaldata));

      await axios
        .put(url, finaldata)
        .then((res) => {
          //console.log(res.data);
          document.location.pathname = "/poi";
        })
        .catch((err) => {
          console.log(err);
          setError(
            "Une erreur s'est produite, veuillez replacer le point sur la carte pour que la modification puisse être effectuée."
          );
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return (
    <div className="bg-bikeexplogray-dark flex flex-row gap-2 md:gap-2 w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-2 md:gap-4 w-full h-screen overflow-y-auto overflow-x-auto m-2 sm:m-4">
        <div className="flex flex-row justify-between w-full items-center bg-bikeexplogray-light text-white rounded-lg p-2 h-12">
          <div className="w-1/2 ml-3 font-bold">
            <h2 className="ml-2 md:ml-3 font-bold text-xs md:text-base">
              Modifier un point d'intérêt
            </h2>
          </div>
          <svg
            className="w-6 h-6"
            fill="salmon"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
            <path
              fillRule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <div className="bg-red-600 rounded-lg text-white text-center w-full">
          {error}
        </div>
        <div className="flex flex-row justify-center w-full gap-6 lg:gap-20">
          {/* Bloc 1 : form */}
          <div className="w-full bg-bikeexplogray-dark flex flex-col md:w-5/12">
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Nom</label>
                <input
                  {...register("title")}
                  className={errors.title ? "input error" : "input"}
                  id="title"
                  type="text"
                  //placeholder={poi?.title}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      title: e.target.value,
                    })
                  }
                />
                <div className="container_error">
                  {errors.title ? (
                    <small className="error">{errors.title.message}</small>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Description</label>
                <textarea
                  {...register("description")}
                  className={errors.description ? "textarea error" : "textarea"}
                  //placeholder={poi?.description}
                  id="description"
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                />
              </div>
              <div className="container_error">
                {errors.description ? (
                  <small className="error">{errors.description.message}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Infos Pratiques</label>
                <input
                  {...register("practicalInfo")}
                  className={errors.practicalInfo ? "input error" : "input"}
                  //placeholder={poi?.practicalInfo}
                  id="practicalInfo"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      practicalInfo: e.target.value,
                    })
                  }
                />
              </div>
              <div className="container_error">
                {errors.practicalInfo ? (
                  <small className="error">
                    {errors.practicalInfo.message}
                  </small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Site Internet</label>
                <input
                  {...register("website")}
                  className={errors.website ? "input error" : "input"}
                  //placeholder={poi?.website}
                  id="website"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      website: e.target.value,
                    })
                  }
                />
              </div>
              <div className="container_error">
                {errors.website ? (
                  <small className="error">{errors.website.message}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Adresse</label>
                <input
                  {...register("address")}
                  className={errors.address ? "input error" : "input"}
                  //placeholder={poi?.address}
                  id="address"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      address: e.target.value,
                    })
                  }
                />
              </div>
              <div className="container_error">
                {errors.address ? (
                  <small className="error">{errors.address.message}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Ville</label>
                <input
                  {...register("city")}
                  className={errors.city ? "input error" : "input"}
                  //placeholder={poi?.city}
                  id="city"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      city: e.target.value,
                    })
                  }
                />
              </div>
              <div className="container_error">
                {errors.city ? (
                  <small className="error">{errors.city.message}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Code postal</label>
                <input
                  {...register("zipcode")}
                  className={errors.zipcode ? "input error" : "input"}
                  //placeholder={poi?.zipcode}
                  id="zipcode"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      zipcode: e.target.value,
                    })
                  }
                />
              </div>
              <div className="container_error">
                {errors.zipcode ? (
                  <small className="error">{errors.zipcode.message}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Latitude</label>
                <input
                  {...register("latitude")}
                  className={errors.latitude ? "input error" : "input"}
                  placeholder={poi?.latitude}
                  id="latitude"
                  value={latClick}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      latitude: e.target.value,
                    });
                    setLatClick(e.target.value);
                  }}
                />
              </div>
              <div className="container_error">
                {errors.latitude ? (
                  <small className="error">{errors.latitude.message}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Longitude</label>
                <input
                  {...register("longitude")}
                  className={errors.longitude ? "input error" : "input"}
                  placeholder={poi?.longitude}
                  id="longitude"
                  value={lngClick}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      longitude: e.target.value,
                    });
                    setLngClick(e.target.value);
                  }}
                />
              </div>
              <div className="container_error">
                {errors.longitude ? (
                  <small className="error">{errors.longitude.message}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {/*  Bloc2 : Carte + image */}
          <div className="flex flex-col gap-4 sm:gap-6 bg-bikeexplogray-dark w-1/2 md:1/2">
            <div className="flex flex-col gap-2">
              {poiIsSuccess &&
                poi?.pictures[0] !== undefined &&
                poi?.pictures.length <= 1 && (
                  <img
                    src={`${apiUrl}${poi.pictures[0].path}`}
                    alt="vignette du POI"
                    className="rounded-lg bg-salmon h-60"
                  />
                )}
              {poiIsSuccess && poi?.pictures[0] === undefined && (
                <img
                  src={``}
                  alt="vignette du POI"
                  className="rounded-lg bg-salmon h-60"
                />
              )}
              {poiIsSuccess &&
                poi?.pictures[0] !== undefined &&
                poi?.pictures.length >= 2 && (
                  <Carousel images={poi.pictures} height={60} />
                )}
              {poiIsSuccess && <UploadPoi PointOfInterestId={id} />}
              {poiIsSuccess && poi?.pictures[0] !== undefined && (
                <div>
                  <h2 className="text-white bg-salmon rounded-t-lg w-full h-9 text-center items-center flex justify-center text-xs md:text-base">
                    Images du point d'intérêt
                  </h2>
                  {isSuccess && (
                    <div className="bg-green-500 text-white text-center flex items-center justify-center text-xs lg:font-bold lg:text-sm">
                      Image supprimée !
                    </div>
                  )}
                  <table className="bg-bikeexplogray-light w-full text-xs md:text-base">
                    <thead>
                      <tr className="text-salmon text-xs sm:text-sm lg:text-base bg-bikeexplogray-dark h-8 lg:h-10">
                        <th className="text-center">Image</th>
                        <th className="text-center">Supprimer</th>
                      </tr>
                    </thead>
                    <tbody>
                      {poi.pictures?.map((picture, index) => {
                        return (
                          <tr
                            className="text-white even:bg-bikeexplogray-dark"
                            key={picture.id}
                          >
                            <td className="text-center my-5 mx-2 h-10">
                              <div className="text-start">
                                Image - {index + 1}
                              </div>
                            </td>
                            <td className="text-center my-5 mx-2 h-10">
                              <button
                                onClick={(e) => {
                                  mutate(picture.id);
                                }}
                              >
                                <svg
                                  id="deleteBtn"
                                  className="w-4 h-4 ml-2 lg:w-6 lg:h-6 lg:ml-5"
                                  fill="none"
                                  stroke="salmon"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  ></path>
                                </svg>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="text-white bg-salmon rounded-b-lg w-full h-3 text-center items-center flex justify-center text-xs md:text-base"></div>
                </div>
              )}
            </div>
            <div className="flex flex-col">
              <div className="w-full bg-salmon flex flex-col gap-1 text-white rounded-t-lg p-1">
                <h1 className="px-2 font-bold text-xs sm:text-base text-center">
                  Carte
                </h1>
                <LocalisationBox setLat={setLat} setLng={setLng} />
              </div>
              <div className="bg-bikeexplogray-light rounded-b-lg p-2 w-full">
                <ReactMapGL
                  className="map-container rounded-lg"
                  onClick={onClick}
                  mapStyle={mapStyle}
                  mapboxApiAccessToken={mapToken}
                  {...viewport}
                  onViewportChange={setViewport}
                >
                  <div className="sidebar bg-bikeexplogray-light">
                    Longitude: {viewport.longitude?.toFixed(4)} | Latitude:
                    {viewport.latitude?.toFixed(4)} | Zoom:
                    {viewport.zoom?.toFixed(1)}
                  </div>
                </ReactMapGL>
              </div>
            </div>
          </div>
        </div>
        {/* Bloc 3 : Créer/Annuler */}
        <div className="flex flex-row gap-3 w-full justify-center mt-4 mb-8">
          <a
            href="/poi"
            className="flex items-center justify-center w-2/6 sm:w-1/6 h-8 sm:h-10 text-white hover:bg-white hover:text-salmon rounded-full bg-salmon border-2 border-salmon hover:border-salmon text-xs md:text-base"
          >
            Annuler
          </a>
          <button
            className="flex items-center justify-center h-8 sm:h-10 w-2/6 sm:w-1/6 font-semibold text-salmon border-salmon rounded-full border-2 border-bikeexplogray-light hover:bg-white hover:text-salmon hover:border-salmon hover:border-1 text-xs md:text-base"
            onClick={() => editPoi()}
          // disabled={isSubmitting || !isValid}
          >
            Modifier
          </button>
        </div>
      </div>
    </div>
  );
}
