import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SideBarAdmin from "../components/sidenavbar";
import Categories from "../components/categoriesTrips";
import axios from "axios";
import BoutonModif from "../components/boutonmodifparcours";
import { apiUrl, frontUrl } from "../utils/urls.js";

export default function OneParcoursAdmin({ data }) {
  const [categorie, getCategorie] = useState([]);

  let { id } = useParams();

  useEffect(() => {
    var config = {
      method: "get",
      url: `${apiUrl}/api/categorytrips/${id}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        getCategorie(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [id]);

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-4 m-2 sm:m-4 w-full h-screen overflow-y-auto">
        <h1 className="text-white font-bold rounded-lg bg-bikeexplogray-light p-2 text-xs md:text-base w-full">
          Les parcours : {categorie.category}
        </h1>
        <h1 className="text-white text-xs md:text-base font-bold">
          Toutes les catégories
        </h1>
        <Categories />
        <div>
          <h2 className="text-white font-semibold my-4 text-xs md:text-base">
            Les Parcours
          </h2>
          <div className="flex flex-row flex-wrap gap-x-3 gap-y-6 w-full">
            {categorie.trips?.map((trip) => {
              return (
                <a
                  key={trip.id}
                  href={`${frontUrl}/parcours/${trip.id}`}
                >
                  <div className="bg-salmon text-white w-20 h-28 lg:w-32 lg:h-48 rounded-lg z-0 relative">
                    {trip && trip?.pictures[0] !== undefined && (
                      <img
                        src={`${apiUrl}${trip.pictures[0].path}`}
                        alt="vignette"
                        className="absolute z-20 w-20 h-28 lg:w-32 lg:h-48 rounded-lg"
                      />
                    )}
                    <BoutonModif className="relative z-30" />
                    <div className="relative z-10 p-1 text-xs lg:text-base">
                      {trip.title}
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
