import React, { useState } from "react";

export default function SearchBarTrips({ placeholder, data, link }) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const handleSearch = (event) => {
    const searchWord = event.target.value;

    setWordEntered(searchWord);

    const newFilter = data.filter((value) => {
      return value.title.toLowerCase().includes(searchWord.toLowerCase());
    });

    setFilteredData(newFilter);

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  return (
    <div className="flex flex-col items-end p-2 static">
      {/* <div className="flex flex-row items-center border-salmon">
        <input
          className="appearance-none bg-transparent border-bikeexplogray-dark placeholder-bikeexplogray-dark text-white w-24 sm:w-52 lg:w-96  h-6 sm:h-8 rounded-lg focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 text-xs md:text-base"
          type="text"
          value={wordEntered}
          onChange={(event) => handleSearch(event)}
          placeholder={placeholder}
        />
        <svg
          className="w-6 h-6 text-salmon flex items-center ml-1 hidden md:block"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
      </div> */}
      <div class="flex items-center justify-center">
        <div class="flex border-2 rounded border-bikeexplogray-dark">
          <input
            type="text"
            className="appearance-none bg-transparent border-none placeholder-bikeexplogray-dark text-white w-24 sm:w-52 lg:w-96  h-6 sm:h-8 rounded-lg focus:outline-none focus:ring-0 focus:border-none focus:z-10 text-xs md:text-base"
            value={wordEntered}
            onChange={(event) => handleSearch(event)}
            placeholder={placeholder}
          />
          <button class="flex items-center justify-center px-4 border-bikeexplogray-dark">
            <svg
              className="w-6 h-6 text-salmon flex items-center ml-1 md:block"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      {filteredData.length !== 0 && (
        <div className="absolute flex flex-col overflow-auto flex-wrap bg-salmon text-white w-1/2 lg:w-96 mr-16 rounded-lg mt-10 p-2">
          {filteredData.map((value, index) => {
            return (
              <a key={value.id} href={link + value.id}>
                <div className="border-b-2 border-white">{value.title}</div>
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
}
