import React, { useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import axios from "axios";
import { apiUrl } from "../../utils/urls.js";

export default function AddFAQ({ data }) {
  const [formData, setFormData] = useState({});
  const [confirm, setConfirm] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [faq, setFaq] = useState("");

  function addFaq(e) {
    e.preventDefault();
    setFaq({ ...faq, formData });
    console.log("formdata", formData);
    console.log("faq", faq);

    data = formData;

    axios
      .post(`${apiUrl}/api/faqs/`, data)
      .then((response) => {
        setFaq(response.data);

        setConfirm(true);

        setTimeout(function () {
          setIsOpen(false);
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function open() {
    setIsOpen(true);
  }
  function close() {
    setIsOpen(false);
  }
  return (
    <>
      <button
        className="mt-2 bg-salmon text-white hover:bg-white hover:text-salmon rounded-full p-3 w-12 h-12 text-center"
        onClick={open}
        title="Ajouter une question/réponse"
      >
        <PlusIcon className="w-6 h-6"></PlusIcon>
      </button>

      {isOpen && (
        <div
          className={`absolute w-full h-full top-0 left-0 z-50 flex justify-center items-center ${
            !isOpen ? "hidden" : ""
          }`}
        >
          <div className="bg-black opacity-50 absolute w-full h-full top-0 left-0"></div>
          <form
            className="flex flex-col gap-2 bg-bikeexplogray-light w-full md:w-1/2 p-5 rounded-lg shadow-md text-salmon prose relative"
            onSubmit={addFaq}
          >
            <h2 className="text-center p-1">Ajouter un élément dans la FAQ</h2>

            <div
              id="confirmmessage"
              className={`rounded-full bg-green-400 text-white text-center w-full text-xs md:text-base ${
                !confirm ? "hidden" : ""
              }`}
            >
              L'élément a bien été ajouté
            </div>
            <div className="flex flex-col gap-2">
              <input
                type="text"
                placeholder="Question"
                className="placeholder-bikeexplogray-light text-white rounded-lg w-full border px-2 bg-bikeexplogray-dark border-salmon"
                onChange={(e) =>
                  setFormData({ ...formData, question: e.target.value })
                }
              />
              <textarea
                onChange={(e) =>
                  setFormData({ ...formData, answer: e.target.value })
                }
                className="placeholder-bikeexplogray-light text-white rounded-lg w-full border px-2 bg-bikeexplogray-dark border-salmon"
                placeholder="Réponse"
              ></textarea>
            </div>
            <div className="flex flex-row gap-1 justify-center">
              <button
                type="submit"
                className="bg-salmon hover:bg-white text-white hover:text-salmon p-2 rounded-lg"
              >
                Ajouter
              </button>
              <button
                className="border-salmon border-2 hover:border-white text-white hover:text-salmon p-2 rounded-lg"
                onClick={close}
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
