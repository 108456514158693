import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import RegistrationAdmin from "../views/registrationAdmin";
import connexionAdmin from "../views/connexionAdmin";
import ProfilAdmin from "../views/profilAdmin";
import AllParcoursAdmin from "../views/parcoursAdmin";
import DetailParcoursAdmin from "../views/détailParcoursAdmin";
import CreateParcours from "../views/creationParcours";
import CatalogueUsers from "../views/ClientManager/catalogueUsersAdmin";
import CreateArticle from "../views/creationArticle";
import DetailArticle from "../views/detailArticle";
import AllArticlesAdmin from "../views/articlesAdmin";
import CategoryTrips from "../views/TripsByCategory";
import CategoryArticles from "../views/ArticlesByCategory";
import CategoriesTags from "../views/manageCategoriesTags";
import TripsClient from "../views/TripsByClient";
import GeoTripsPoi from "../components/GeoTripsPoi/geotripspoi";
import ResetPasswordAdmin from "../views/resetPasswordAdmin";
import poiAdmin from "../views/Localisation/poiAdmin";
import createPoi from "../views/createPoi";
import EditPoi from "../views/editPoi";
import TripStepTwo from "../views/createParcoursStep2";
import PoiStepTwo from "../views/createPoiStep2";
import ArticleStep2 from "../views/createArticleStep2";
import KeyIndicators from "../views/keyIndicators";
import ProtectedRoute from "../components/protectedRoute";
import ProfilClient from "../views/ClientManager/DetailsClient";
import CreateClient from "../views/ClientManager/addClient";
import CreateClient2 from "../views/ClientManager/addClient2";
import ManageFaqs from "../views/manageFaq";
import ManageProducts from "../views/manageProducts";

export default function Views() {
  return (
    <Router>
      <Switch>
        <Route path="/connexion" component={connexionAdmin}></Route>
        <Route path="/inscription" component={RegistrationAdmin}></Route>
        <ProtectedRoute
          exact
          path="/"
          component={AllParcoursAdmin}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/addclient/:tripId"
          component={CreateClient}
        ></ProtectedRoute>
        <ProtectedRoute path="/faq" component={ManageFaqs}></ProtectedRoute>
        <ProtectedRoute
          path="/product"
          component={ManageProducts}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/addclient2/:id/:tripId"
          component={CreateClient2}
        ></ProtectedRoute>
        <ProtectedRoute path="/profil" component={ProfilAdmin}></ProtectedRoute>
        <ProtectedRoute
          path="/parcours/:id"
          component={DetailParcoursAdmin}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/creationparcours"
          component={CreateParcours}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/utilisateurs"
          component={CatalogueUsers}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/profilclient/:id"
          component={ProfilClient}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/creationarticle"
          component={CreateArticle}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/article/:id"
          component={DetailArticle}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/allarticles"
          component={AllArticlesAdmin}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/categorie/:id"
          component={CategoryTrips}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/categoriearticles/:id"
          component={CategoryArticles}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/categoriesettags"
          component={CategoriesTags}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/parcoursclient/:id"
          component={TripsClient}
        ></ProtectedRoute>
        <ProtectedRoute path="/poi" component={poiAdmin}></ProtectedRoute>
        <ProtectedRoute
          path="/createPoi"
          component={createPoi}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/editPoi/:id"
          component={EditPoi}
        ></ProtectedRoute>
        <Route
          path="/reset_password/admins/:token"
          component={ResetPasswordAdmin}
        ></Route>
        <ProtectedRoute
          path="/parcour/:id"
          component={GeoTripsPoi}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/creationparcours2/:id"
          component={TripStepTwo}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/creationpoi2/:id"
          component={PoiStepTwo}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/createarticle2/:id"
          component={ArticleStep2}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/chiffresclés"
          component={KeyIndicators}
        ></ProtectedRoute>
      </Switch>
    </Router>
  );
}
