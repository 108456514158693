import React, { useState, useEffect } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";
import LogoTransp from "./logoBEtransp.png";
import { apiUrl } from "../utils/urls.js";

export default function RegistrationAdmin({ data }) {
  const [formData, setFormData] = useState({});
  const [admin, setAdmin] = useState(data);
  const [confirm, setConfirm] = useState({});
  const [error, setError] = useState("");

  //Show/Hide password
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  async function registration(e) {
    e.preventDefault();
    setAdmin({ ...admin, formData });

    const password = formData.password;
    const confirmpassword = formData.confirmpassword;

    var axios = require("axios");
    var data = formData;
    var config = {
      method: "post",
      url: `${apiUrl}/api/admins`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    if (password === confirmpassword) {
      axios(config)
        .then(function (response) {
          const mess = "Votre compte a été crée";
          setTimeout(function () {
            // after 2 seconds
            document.location.pathname = "/connexion";
          }, 3000);
          setConfirm({ ...confirm, mess });
        })
        .catch(function (error) {
          console.log(error);
          setError(
            "Une erreur s'est produite. Veillez rééssayer avec un nom d'utilisateur, une adresse email valide, un mot de passe d'au moins 6 caractères contenant au moins une lettre majuscule, une lettre minsucule, un chiffre et un caractère spécial et une confirmation du mot de passe."
          );
        });
    }
  }

  useEffect(() => {
    setConfirm({ ...confirm, formData });
    const password = formData.password;
    const confirmpassword = formData.confirmpassword;
    const email = formData.email;

    if (email?.length === 0) {
      const message = "L'email est requis";
      setConfirm({ ...confirm, message });
      return message;
    } else if (email?.length < 6) {
      const message = "L'email doit contenir au moins 6 caractères";
      setConfirm({ ...confirm, message });
      return message;
    } else if (email?.indexOf(" ") >= 0) {
      const message = "L'email ne doit pas contenir d'espace";
      setConfirm({ ...confirm, message });
      return message;
    }

    if (password?.length <= 6) {
      const message = "Le mot de passe doit contenir au moins 6 caractères";
      setConfirm({ ...confirm, message });
      return message;
    } else if (password?.indexOf(" ") >= 0) {
      const message = "Le mot de passe ne peut pas contenir d'espace";
      setConfirm({ ...confirm, message });
      return message;
    } else if (
      password?.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+]/) !== -1
    ) {
      const message =
        "Le mot de passe doit contenir au moins un caractère spécial";
      setConfirm({ ...confirm, message });
      return message;
    }

    if (password === undefined || confirmpassword === undefined) {
      const message =
        "Le mot de passe et la confirmation de mot de passe sont requis";
      setConfirm({ ...confirm, message });
      return message;
    } else if (password === confirmpassword) {
      const message = "";
      setConfirm({ ...confirm, message });
      return message;
    } else if (password !== confirmpassword) {
      const message = "Les mots de passe ne correspondent pas";
      setConfirm({ ...confirm, message });
      //console.log(confirm);
      return message;
    }
  }, [formData]);

  return (
    <div className="bg-bikeexplogray-dark min-h-full h-screen w-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 overflow-y-auto">
      <div className="max-w-md w-full space-y-8">
        <div>
          <div className="bg-green-400 rounded-lg text-white text-center w-full ">
            {" "}
            {confirm.mess}
          </div>
          <div className="bg-red-600 rounded-lg text-white text-center w-full ">
            {" "}
            {error}
          </div>
          <img
            className="mx-auto h-44 sm:h-60 w-auto"
            src={LogoTransp}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-base sm:text-2xl font-extrabold text-white">
            Inscrivez-vous à Bike Explo Admin
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={registration}>
          <p class="bg-red-600 text-white rounded-lg w-full text-center">
            {" "}
            {confirm.message}
          </p>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">
                Nom d'utilisateur
              </label>
              <input
                id="username"
                name="username"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 rounded-t-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
                placeholder="Nom d'utilisateur (minimum 6 caractères)"
                onChange={(e) =>
                  setFormData({ ...formData, username: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email
              </label>
              <input
                id="email-address"
                name="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
                placeholder="Email"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>

            <div className="flex flex-row relative">
              <label htmlFor="password" className="sr-only">
                Mot de passe
              </label>
              <input
                id="password"
                name="password"
                type={passwordShown ? "text" : "password"}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-xs text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xs sm:text-sm"
                placeholder="Mot de passe (avec majuscule, chiffre et caractères spéciaux)"
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
              <button
                className="absolute inset-y-0 right-0 pr-2 text-salmon hover:text-bikeexplogray-light"
                onClick={togglePassword}
              >
                <svg
                  className="w-5 h-5 sm:w-6 sm:h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  ></path>
                </svg>
              </button>
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Confirmation du mot de passe
              </label>
              <input
                id="password"
                name="password"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 rounded-b-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm"
                placeholder="Confirmation du mot de passe"
                onChange={(e) =>
                  setFormData({ ...formData, confirmpassword: e.target.value })
                }
              />
            </div>
          </div>

          <div className="flex items-center justify-between"></div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 bg-salmon text-sm font-medium rounded-lg text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-salmon text-xs sm:text-sm"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-white group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              Inscription
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
