import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl, frontUrl } from "../utils/urls.js";

export default function Categories() {
  const [categorie, getCategorie] = useState([]);

  useEffect(() => {
    axios(`${apiUrl}/api/categorytrips`)
      .then((response) => {
        getCategorie(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="flex flex-row gap-1 sm:gap-2 w-full overflow-auto">
      {categorie.map((cat) => {
        return (
          <a key={cat.id} href={`${frontUrl}/categorie/${cat.id}`}>
            <h2 className="flex whitespace-nowrap w-full rounded-full bg-salmon text-white p-2 text-xs md:text-base hover:bg-hoversalmon">
              {cat.category}
            </h2>
          </a>
        );
      })}
    </div>
  );
}
