import * as Yup from "yup";

export default Yup.object().shape({
  title: Yup.string(),
  description: Yup.string(),
  practicalInfos: Yup.string(),
  website: Yup.string().url(
    "Veuillez saisir une URL valide (https://www.google.fr)"
  ),
  address: Yup.string(),
  city: Yup.string(),
  zipCode: Yup.string(),
  latitude: Yup.number()
    .typeError("La latitude doit uniquement comporter des chiffres"),
/*     .positive("La latitude doit être positive")
    .min(0, "La latitude doit être positive"), */
  longitude: Yup.number()
    .typeError("La longitude doit uniquement comporter des chiffres")
   /*  .positive("La longitude doit être positive")
    .min(0, "La longitude doit être positive"), */
});
