import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../utils/urls.js";

// COMPONENTS
import SideBarAdmin from "../components/sidenavbar";
import Upload from "../components/upload";
import GeoTripsPoi from "../components/GeoTripsPoi/geotripspoi";
import UploadGpx from "../components/uploadgpx";
import AddClientButton from "../components/addClientButton";

// REACT QUERY
import { useQuery, useMutation, useQueryClient } from "react-query";

export default function OneParcoursAdmin({ data }) {
  const [formData, setFormData] = useState({});
  const [parcours, setParcours] = useState();
  const [confirm, setConfirm] = useState({});
  const [status, setStatus] = useState();
  const [error, setError] = useState("");
  const [popup, setPopup] = useState(false);
  const [level, setLevel] = useState("");
  //scrolling menu select clients
  const [allClients, getClients] = useState();
  //scrolling menu select categories
  const [allCategories, getCategories] = useState([]);
  //scrolling menu select poi
  const [allPoi, getPoi] = useState([]);

  let { id } = useParams();

  async function updateParcours(e) {
    e.preventDefault();
    setParcours({ ...parcours, formData });

    data = formData;

    axios
      .put(`${apiUrl}/api/trips/${id}`, data)
      .then(function (response) {
        setParcours(response.data);
        //confirmation message
        console.log(response.data);
        const mess =
          "Votre parcours est bien créé, vous allez être redirigé vers le catalogue des parcours";

        setConfirm({ ...confirm, mess });

        setTimeout(function () {
          // after 2 seconds
          document.getElementById("confirmmessage").style.visibility = "hidden";
        }, 2000);

        setTimeout(function () {
          // after 2 seconds
          document.location.pathname = "/";
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
        setError("Une erreur s'est produite, veuillez rééssayer.");
      });

    updateLevel();
  }

  function updateLevel() {
    axios.put(`${apiUrl}/api/trips/${id}`, { elevation: level });
    console.log("level updated");
    console.log(level);
  }

  function onChangeHandler() {
    // GET SELECT VALUE
    if (document.getElementById("categorie") != null) {
      let selectvalue = document.getElementById("categorie").value;

      // GET ID ON SELECT VALUE
      let selectid = selectvalue.split(" ");
      return selectid[0];
    }
  }

  //associate category to trip
  async function addCategoryToParcours() {
    let selectedValue = onChangeHandler();

    var data = JSON.stringify({
      trip_id: id,
      categorytrip_id: selectedValue,
    });

    var config = {
      method: "post",
      url: `${apiUrl}/api/category_trips`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //delete category from trip
  async function deleteCat(e, catid) {
    axios
      .delete(
        `${apiUrl}/api/category_trips/cat/${catid}/${id}`
      )
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
    window.location.reload();
  }

  function clientChangeHandler() {
    // GET SELECT VALUE
    if (document.getElementById("client") != null) {
      let selectedvalue = document.getElementById("client").value;

      // GET ID ON SELECT VALUE
      let clientid = selectedvalue.split(" ");
      return clientid[0];
    }
  }

  function poiChangeHandler() {
    // GET SELECT VALUE
    if (document.getElementById("poi") != null) {
      let selectedpoi = document.getElementById("poi").value;

      // GET ID ON SELECT VALUE
      let poiid = selectedpoi.split(" ");
      return poiid[0];
    }
  }

  function addClientToParcours() {
    let client = clientChangeHandler();

    var data = JSON.stringify({
      clientId: client,
    });

    var config = {
      method: "put",
      url: `${apiUrl}/api/trips/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }

  function addPoiToParcours() {
    let pointofinterest = poiChangeHandler();

    var data = JSON.stringify({
      trip_id: id,
      pointOfInterest_id: pointofinterest,
    });

    var config = {
      method: "post",
      url: `${apiUrl}/api/pointTrip/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //handle checkboxes
  async function handleCheckBoxPremium() {
    if (document.getElementById("premium").checked === true) {
      await setFormData({ ...formData, premium: 1 });
    } else await setFormData({ ...formData, premium: 0 });
  }

  async function handleCheckBoxPublished() {
    if (document.getElementById("published").checked === true) {
      await setFormData({ ...formData, published: false });
    } else await setFormData({ ...formData, published: true });
  }

  //show trip and categorie list

  const queryKey = ["trip"];
  const getTrip = async () => {
    const { data } = await axios.get(
      `${apiUrl}/api/trips/${id}`
    );
    return data;
  };

  const useTrip = () => useQuery(queryKey, getTrip);
  const {
    data: trip,
    isSuccess: tripIsSuccess,
    isLoading: tripIsLoading,
    isError: tripIsError,
  } = useTrip();

  // DELETE PICTURE
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    async (pictureid) => {
      let url = `${apiUrl}/api/pictures/${pictureid}`;
      await axios
        .delete(url)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  useEffect(() => {
    var config2 = {
      method: "get",
      url: `${apiUrl}/api/categorytrips`,
      headers: {},
    };

    axios(config2)
      .then(function (response) {
        getCategories(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${apiUrl}/api/clients`)
      .then(function (response) {
        getClients(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });

    //show poi list
    axios
      .get(`${apiUrl}/api/pointsofinterest`)
      .then(function (response) {
        getPoi(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [id]);

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />

      <div className="flex flex-col gap-2 lg:gap-4 m-2 sm:m-4 w-full h-screen overflow-y-auto">
        <h1 className="text-white font-bold rounded-lg bg-bikeexplogray-light p-2 flex flex-row justify-between gap-1 sm:gap-2 h-10 lg:h-12 items-center text-xs md:text-base">
          Créer un nouveau parcours : étape 2
          <svg
            className="w-5 h-5 lg:w-6 lg:h-6 "
            fill="salmon"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
            <path
              fillRule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clipRule="evenodd"
            ></path>
          </svg>
        </h1>
        <div
          id="confirmmessage"
          className="rounded-full bg-green-400 text-white text-center w-full text-xs md:text-base"
        >
          {" "}
          {confirm.mess}{" "}
        </div>
        <div className="rounded-full bg-red-600 text-white text-center w-full text-xs md:text-base">
          {error}
        </div>

        {/*  Partie 2 : Carte et résumé du parcours */}
        <div className="flex flex-col-reverse sm:flex-row sm:h-2/5 gap-4 w-full">
          <div className="w-full sm:w-1/2 sm:h-full">
            <h1 className="bg-salmon text-white rounded-t-lg p-1 text-center text-xs md:text-base">
              Carte
            </h1>
            <div className="flex flex-col gap-2 justify-center bg-bikeexplogray-light rounded-b-lg p-2 h-32 sm:h-full">
              <GeoTripsPoi />
            </div>
          </div>
          <div className="flex flex-col gap-4 sm:gap-8 sm:w-1/2 sm:h-full">
            <div className="flex flex-row gap-4 w-full sm:h-full">
              <div className="sm:h-full w-1/2">
                <h1 className="bg-salmon text-white rounded-t-lg p-1 text-center text-xs md:text-base">
                  Résumé du parcours
                </h1>
                <div className="flex flex-col gap-2 justify-center bg-bikeexplogray-light rounded-b-lg p-2 h-5/6">
                  <div className="flex flex-col md:flex-row text-xs md:text-base">
                    <label className="text-white w-full md:w-2/3">
                      Durée (h):
                    </label>
                    <input
                      id="durée"
                      name="durée"
                      type="number"
                      className="h-8 w-full md:w-1/3 border-bikeexplogray-dark appearance-none rounded-lg bg-bikeexplogray-dark placeholder-gray-200 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                      placeholder={trip?.duration}
                      onChange={(e) =>
                        setFormData({ ...formData, duration: e.target.value })
                      }
                    />
                  </div>
                  <div className="flex flex-col md:flex-row text-xs md:text-base">
                    <label className="text-white w-full md:w-2/3">
                      Difficulté :
                    </label>

                    <select
                      type="text"
                      placeholder="Niveau"
                      id="niveau"
                      value={level}
                      onChange={(e) => setLevel(e.target.value)}
                      className="h-10 appearance-none w-full rounded-lg
                      bg-bikeexplogray-dark border-bikeexplogray-dark
                      text-bikeexplogray-light focus:ring-salmon
                      focus:border-salmon focus:z-10 text-xs sm:text-base"
                    >
                      <option className="text-xs md:text-sm">
                        Sélectionner le niveau du parcours
                      </option>
                      <option>néo-cycliste</option>
                      <option>cycliste occasionel</option>
                      <option>cycliste habitué</option>
                      <option>cycliste sportif</option>
                    </select>
                  </div>
                  <div className="flex flex-col md:flex-row text-xs md:text-base">
                    <label className="text-white w-full md:w-2/3">
                      Distance (km):
                    </label>
                    <input
                      id="distance"
                      name="distance"
                      type="number"
                      className="h-8 w-full md:w-1/3 border-bikeexplogray-dark appearance-none rounded-lg bg-bikeexplogray-dark placeholder-gray-200 text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 sm:text-sm"
                      placeholder={trip?.distance}
                      onChange={(e) =>
                        setFormData({ ...formData, distance: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="sm:h-full w-1/2">
                <h1 className="bg-salmon text-white rounded-t-lg p-1 text-center text-xs md:text-base">
                  Catégories
                </h1>
                <div className="flex flex-col gap-2 bg-bikeexplogray-light rounded-b-lg p-2 h-5/6">
                  <div className="self-center flex flex-row gap-2 w-5/6">
                    <select
                      type="text"
                      placeholder="Catégorie"
                      id="categorie"
                      onChange={onChangeHandler}
                      className="h-10 appearance-none w-full rounded-lg bg-bikeexplogray-dark  border-bikeexplogray-dark text-bikeexplogray-light focus:ring-salmon focus:border-salmon focus:z-10 text-xs sm:text-base"
                    >
                      <option className="text-xs md:text-sm">
                        Ajouter une catégorie
                      </option>
                      {allCategories.map((cat) => {
                        return (
                          <option key={cat.id} value={cat.id}>
                            {cat.id} {cat?.category}
                          </option>
                        );
                      })}
                    </select>
                    <button
                      className="bg-salmon text-white rounded-full p-1 border-2 border-salmon hover:text-salmon hover:bg-white text-xs md:text-base"
                      onClick={addCategoryToParcours}
                    >
                      OK
                    </button>
                  </div>
                  <div className="self-center flex flex-row flex-wrap gap-2 overflow-y-auto">
                    {tripIsSuccess &&
                      trip.categories?.map((cat) => {
                        return (
                          <div
                            className="flex flex-row gap-1 rounded-lg bg-bikeexplogray-dark p-1 text-white text-xs md:text-base"
                            key={cat.id}
                          >
                            <button
                              className="items-center rounded-full bg-bikeexplogray-light p-1"
                              onClick={(e) => deleteCat(e, cat.id)}
                            >
                              <svg
                                className="lg:w-3 lg:h-3 w-2 h-2"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"
                                ></path>
                              </svg>
                            </button>
                            {cat.category}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-2 h-full">
              {" "}
              <div className="flex flex-col gap-2 w-1/2">
                {tripIsSuccess && trip?.pictures[0] !== undefined && (
                  <img
                    src={`${apiUrl}${trip.pictures[0].path}`}
                    alt="vignette du parcours"
                    className="rounded-lg h-24 object-contain"
                  />
                )}
                {tripIsSuccess && trip.pictures[0] !== undefined && (
                  <button
                    className="text-white rounded-full p-1 bg-salmon border-2 border-salmon hover:bg-white hover:text-salmon text-xs md:text-base"
                    type="button"
                    onClick={(e) => {
                      mutate(trip.pictures[0].id);
                      setPopup(true);
                      setTimeout(() => setPopup(false), 2000);
                    }}
                  >
                    Supprimer une image
                  </button>
                )}
                {isSuccess && popup && (
                  <div className="text-white rounded-full p-1 bg-green-500 border-2 text-center border-green-500 ext-xs md:text-base">
                    Image supprimée
                  </div>
                )}
                {tripIsSuccess && trip.pictures[0] === undefined && (
                  <Upload tripId={id} />
                )}{" "}
              </div>
              {tripIsSuccess && trip.pictures[0] === undefined ? (
                <div className="flex mx-auto md:w-72">
                  <UploadGpx id={id} />
                </div>
              ) : (
                <div className="flex mx-auto items-end md:w-72">
                  <UploadGpx id={id} />
                </div>
              )}
            </div>
          </div>
        </div>

        {/*  Partie 3 : Etapes/POI */}
        <div className="self-center flex flex-col gap-2 w-full mt-8 sm:mt-14">
          <div className="flex flex-row gap-2">
            <select
              type="text"
              placeholder="Point d'intérêt"
              id="poi"
              onChange={poiChangeHandler}
              className="w-full border-salmon appearance-none rounded-lg bg-bikeexplogray-dark text-bikeexplogray-light focus:ring-salmon focus:border-salmon focus:z-10 text-xs md:text-base"
            >
              <option className="text-xs md:text-sm">
                Ajouter les points d'intérêt dans l'ordre du parcours
              </option>
              {allPoi.map((poi) => {
                return (
                  <option
                    key={poi.id}
                    value={poi.id}
                    className="text-xs md:text-base"
                  >
                    {poi.id} {poi?.title}
                  </option>
                );
              })}
            </select>
            <button
              className="bg-salmon text-white rounded-full p-1 border-2 border-salmon hover:text-salmon hover:bg-white text-xs md:text-base"
              onClick={addPoiToParcours}
            >
              Ok
            </button>
          </div>
          {tripIsSuccess && trip?.pointsOfInterest?.length !== 0 && (
            <div className="flex flex-col mt-4">
              <h2 className="text-white bg-salmon rounded-t-lg w-full h-9 text-center items-center flex justify-center text-xs md:text-base">
                {" "}
                Points d'intérêt sur le parcours
              </h2>
              <table className="bg-bikeexplogray-light w-full text-xs md:text-base">
                <tbody>
                  {trip.pointsOfInterest?.map((item, index) => {
                    return (
                      <tr
                        className="text-white even:bg-bikeexplogray-dark"
                        key={item.id}
                      >
                        <td className="text-center my-5 mx-2 h-10">
                          <div className="text-start">{item?.title}</div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="w-full bg-salmon h-2 rounded-b-lg"></div>
            </div>
          )}
        </div>
        {/* Partie 3 : Réglages premium, brouillon, restreint */}
        <div className="flex flex-row justify-evenly gap-2 md:gap-6 w-full my-7">
          <div className="flex flex-row items-center justify-between gap-1 md:gap-2 bg-bikeexplogray-light rounded-lg p-2 w-1/3 md:w-1/4 h-12 md:h-14">
            <label className="text-white text-xs md:text-base">Premium</label>
            <input
              id="premium"
              name="premium"
              type="checkbox"
              defaultChecked={false}
              className="appearance-none mr-2 rounded-full focus:ring-salmon"
              onClick={handleCheckBoxPremium}
            />
          </div>

          <div className="flex flex-row items-center justify-between gap-1 md:gap-2 bg-bikeexplogray-light rounded-lg p-2 w-1/3 md:w-1/4 h-12 md:h-14">
            <label className="text-white text-xs md:text-base">Brouillon</label>

            <input
              id="published"
              name="published"
              type="checkbox"
              defaultChecked={true}
              className="appearance-none mr-2 rounded-full focus:ring-salmon"
              onClick={handleCheckBoxPublished}
            />
          </div>
          <div className="flex flex-row items-center justify-between gap-2 bg-bikeexplogray-light rounded-lg p-2 w-1/3 md:w-1/4 h-12 md:h-14">
            <select
              id="client"
              name="client"
              className="appearance-none rounded-lg relative block w-full px-3 py-2 bg-bikeexplogray-dark border-bikeexplogray-dark text-bikeexplogray-light text-white focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 text-xs md:text-base"
              placeholder="Nom du client"
              onChange={clientChangeHandler}
            >
              <option className="text-sm">Modifier le client</option>
              {allClients?.map((client) => {
                return (
                  <option key={client.id} value={client.id}>
                    {client.id} {client.storeName}
                  </option>
                );
              })}
            </select>
            <button
              className="bg-salmon text-white rounded-full p-1 border-2 border-salmon hover:text-salmon hover:bg-white text-xs md:text-base"
              onClick={addClientToParcours}
            >
              Ok
            </button>

            <AddClientButton id={id} />
          </div>
        </div>
        {/* Partie 4 : Confirmation */}
        <div className="flex flex-row justify-center gap-2 w-full mb-12 mt-4">
          <button
            className="bg-salmon rounded-full p-2 text-white border-2 border-salmon hover:text-salmon hover:bg-white w-1/2 text-xs md:text-base"
            onClick={updateParcours}
          >
            Valider & terminer
          </button>
        </div>
      </div>
    </div>
  );
}
