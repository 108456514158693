import React, { useState, useCallback, useEffect } from "react";
import "../views/Localisation/localisationAdmin.css";
import ReactMapGL, { FlyToInterpolator } from "react-map-gl";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiUrl } from "../utils/urls.js";

// IMPORT COMPONENTS
import SideBarAdmin from "../components/sidenavbar";
import axios from "axios";
import LocalisationBox from "../components/LocalisationBox/localisationbox";
import "./form.css";
import validationFormPoi from "../utils/validationFormPoi";
import "../index.css";

export default function CreatePoi({ data }) {
  // VARS
  const mapToken =
    "pk.eyJ1IjoicGFyY291cnMtbWFwczEiLCJhIjoiY2x2YjBuM2dtMDNhNzJqcDc2ZWMwbXd0ayJ9.dgYngPnMcgiHigv4sexwXQ";
  const mapStyle = "mapbox://styles/mapbox/streets-v11";
  const [lng, setLng] = useState(5.36978);
  const [lat, setLat] = useState(43.296482);
  const [lngClick, setLngClick] = useState("");
  const [latClick, setLatClick] = useState("");
  const [zoom, setZoom] = useState(12.5);
  const [formData, setFormData] = useState({});
  const [poi, setPoi] = useState(data);
  const [viewport, setViewport] = React.useState({
    longitude: lng,
    latitude: lat,
    zoom: zoom,
    width: "100%",
    height: "30vh",
  });
  const [confirm, setConfirm] = useState({});
  const [error, setError] = useState({});
  const [showButton, setShowButton] = useState(false);
  const [hideButton, setHideButton] = useState(true);

  // ERRORS VARS
  const {
    register,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(validationFormPoi),
  });

  function createPoi(/* e */) {
    //e.preventDefault();
    setPoi({ ...poi, formData });

    let coordonates = {
      latitude: latClick,
      longitude: lngClick,
    };

    data = formData;

    const finaldata = Object.assign(data, coordonates);
    console.log(finaldata);

    axios
      .post(`${apiUrl}/api/pointsOfInterest/`, finaldata)
      .then((response) => {
        setPoi(response.data);
        //confirmation message
        const mess = "Votre point d'intérêt a bien été mis en ligne";

        setConfirm({ ...confirm, mess });
        setTimeout(function () {
          // after 2 seconds
          document.getElementById("confirmmessage").style.visibility = "hidden";
        }, 3000);
      })
      .catch((error) => {
        console.log(error.response.data);

        const message =
          "Le point d'intérêt n'a pas pu être créé en raison d'une erreur. Veuillez bien vérifier que les textes ne contiennent pas de caractères spéciaux.";

        setError({ ...error, message });

        setTimeout(function () {
          window.location.reload();
        }, 3000);
      });

    setShowButton(true);
    setHideButton(false);
  }

  function NextStep() {
    const ID = poi.id;
    console.log(ID);

    //confirmation message
    const mess = "Vous allez maintenant passer à la deuxième étape";

    setConfirm({ ...confirm, mess });
    setTimeout(function () {
      // after 2 seconds
      document.getElementById("confirmmessage").style.visibility = "hidden";
    }, 3000);
    setTimeout(function () {
      // after 2 seconds
      document.location.pathname = "/creationpoi2/" + ID;
    }, 3000);
  }

  const onClick = useCallback((event) => {
    const lng = event.lngLat[0];
    const lat = event.lngLat[1];
    setLatClick(lat);
    setLngClick(lng);
  });

  useEffect(() => {
    setViewport({
      latitude: lat,
      longitude: lng,
      zoom: zoom,
      transitionInterpolator: new FlyToInterpolator({ speed: 1.0 }),
      transitionDuration: "auto",
      width: "100%",
      height: "30vh",
    });
  }, [lat, lng, zoom]);

  function requiredImg() {
    return (
      <svg
        className="w-4 h-4"
        fill="salmon"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  }

  return (
    <div className="bg-bikeexplogray-dark flex flex-row gap-2 md:gap-4 w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-2 md:gap-4 w-full h-screen overflow-y-auto overflow-x-auto m-2 sm:m-4">
        <div className="flex flex-row justify-between w-full items-center bg-bikeexplogray-light text-white rounded-lg p-2 h-12">
          <h2 className="ml-2 md:ml-3 font-bold text-xs md:text-base">
            Créer Point d'intérêt
          </h2>

          <svg
            className="w-5 h-5 md:w-6 md:h-6 md:mr-5 mr-3"
            fill="salmon"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
            <path
              fillRule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <div
          id="confirmmessage"
          class="bg-green-400 rounded-lg text-white text-xs sm:text-base text-center w-full "
        >
          {" "}
          {confirm.mess}{" "}
        </div>
        <div className="flex flex-row justify-center w-fit gap-6 lg:gap-20">
          {/* Bloc 1 : form */}
          <div className="w-full bg-bikeexplogray-dark flex flex-col md:w-5/12">
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Nom</label>
                <input
                  {...register("title")}
                  className={errors.title ? "input error" : "input"}
                  placeholder={poi?.title}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      title: e.target.value,
                    })
                  }
                />
                <div className="container_error">
                  {errors.title ? (
                    <small className="error">{errors.title.message}</small>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Description</label>
                <textarea
                  {...register("description")}
                  className={errors.description ? "textarea error" : "textarea"}
                  placeholder={poi?.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                />
              </div>
              <div className="container_error">
                {errors.description ? (
                  <small className="error">{errors.description.message}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Infos Pratiques</label>
                <input
                  {...register("practicalInfo")}
                  className={errors.practicalInfo ? "input error" : "input"}
                  placeholder={poi?.practicalInfo}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      practicalInfo: e.target.value,
                    })
                  }
                />
              </div>
              <div className="container_error">
                {errors.practicalInfo ? (
                  <small className="error">
                    {errors.practicalInfo.message}
                  </small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Site Internet</label>
                <input
                  {...register("website")}
                  className={errors.website ? "input error" : "input"}
                  placeholder={poi?.website}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      website: e.target.value,
                    })
                  }
                />
              </div>
              <div className="container_error">
                {errors.website ? (
                  <small className="error">{errors.website.message}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Adresse</label>
                <input
                  {...register("address")}
                  className={errors.address ? "input error" : "input"}
                  placeholder={poi?.address}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      address: e.target.value,
                    })
                  }
                />
              </div>
              <div className="container_error">
                {errors.address ? (
                  <small className="error">{errors.address.message}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Ville</label>
                <input
                  {...register("city")}
                  className={errors.city ? "input error" : "input"}
                  placeholder={poi?.city}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      city: e.target.value,
                    })
                  }
                />
              </div>
              <div className="container_error">
                {errors.city ? (
                  <small className="error">{errors.city.message}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Code postal</label>
                <input
                  {...register("zipcode")}
                  className={errors.zipcode ? "input error" : "input"}
                  placeholder={poi?.zipcode}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      zipcode: e.target.value,
                    })
                  }
                />
              </div>
              <div className="container_error">
                {errors.zipcode ? (
                  <small className="error">{errors.zipcode.message}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Latitude</label>
                <input
                  {...register("latitude")}
                  className={errors.latitude ? "input error" : "input"}
                  placeholder={poi?.latitude}
                  value={latClick}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      latitude: e.target.value,
                    });
                    setLatClick(e.target.value);
                  }}
                />
              </div>
              <div className="container_error">
                {errors.latitude ? (
                  <small className="error">{errors.latitude.message}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="my-4">
              <div className="flex flex-row items-center">
                <label className="label">Longitude</label>
                <input
                  {...register("longitude")}
                  className={errors.longitude ? "input error" : "input"}
                  placeholder={poi?.longitude}
                  value={lngClick}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      longitude: e.target.value,
                    });
                    setLngClick(e.target.value);
                  }}
                />
              </div>
              <div className="container_error">
                {errors.longitude ? (
                  <small className="error">{errors.longitude.message}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {/*  Bloc2 : Carte + image */}
          <div className="flex flex-col bg-bikeexplogray-dark w-1/2 md:2/3">
            <div className="w-full bg-salmon flex flex-col gap-1 text-white rounded-t-lg p-1">
              <h1 className="px-2 font-bold text-xs sm:text-base text-center">
                Carte
              </h1>
              <LocalisationBox setLat={setLat} setLng={setLng} />
            </div>
            <div className="bg-bikeexplogray-light rounded-b-lg p-2 w-full">
              <ReactMapGL
                className="map-container rounded-lg"
                onClick={onClick}
                mapStyle={mapStyle}
                mapboxApiAccessToken={mapToken}
                {...viewport}
                onViewportChange={setViewport}
              >
                <div className="sidebar bg-bikeexplogray-light">
                  Longitude: {viewport.longitude.toFixed(4)} | Latitude:{" "}
                  {viewport.latitude.toFixed(4)} | Zoom:{" "}
                  {viewport.zoom.toFixed(1)}
                </div>
              </ReactMapGL>
            </div>
          </div>
        </div>
        {/* Bloc 3 : Créer/Annuler */}{" "}
        <div class="bg-red-600 rounded-lg text-white text-xs sm:text-base text-center w-full ">
          {" "}
          {error.message}{" "}
        </div>
        <div className="flex flex-row gap-3 w-full justify-center mt-4 mb-8">
          <div className="flex flex-row gap-2 justify-center w-full my-4">
            {hideButton ? (
              <button
                className="flex items-center justify-center w-2/6 sm:w-1/6 h-8 sm:h-10 text-white hover:bg-white hover:text-salmon rounded-full bg-salmon border-2 border-salmon hover:border-salmon text-xs md:text-base"
                onClick={createPoi}
                disabled={isSubmitting}
              >
                Valider
              </button>
            ) : null}
            {/*  {!isValid && (
            <p className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              Formulaire non valide, veuillez remplir tous les champs !
            </p>
          )} */}
            {showButton ? (
              <button
                className="flex items-center justify-center w-2/6 sm:w-1/6 h-8 sm:h-10 text-white hover:bg-white hover:text-salmon rounded-full bg-salmon border-2 border-salmon hover:border-salmon text-xs md:text-base"
                onClick={NextStep}
              >
                Passer à l'étape 2
              </button>
            ) : null}
            <a
              href="/poi"
              className="flex items-center justify-center h-8 sm:h-10 w-2/6 sm:w-1/6 font-semibold text-salmon border-salmon rounded-full border-2 border-bikeexplogray-light hover:bg-white hover:text-salmon hover:border-salmon hover:border-1 text-xs md:text-base"
            >
              Annuler
            </a>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
