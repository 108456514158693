import React, { useState } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";
import LogoTransp from "./logoBEtransp.png";
import axios from "axios";
import { apiUrl } from "../utils/urls.js";
import { useParams } from 'react-router-dom';

// ERRORS FORM
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function ResetPasswordAdmin({ data }) {

  const { token: passwordToken } = useParams();

  const [formData, setFormData] = useState({});
  const [admin, setAdmin] = useState(data);

  // ERRORS VALIDATIONS
  let schema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Le mot de passe doit comporter au moins 8 caractères")
      .required("Le mot de passe est requis !"),
    confirmPassword: yup
      .string()
      .min(8, "Le mot de passe doit comporter au moins 8 caractères")
      .required("Le mot de passe est requis !"),
  });

  // ERRORS VARS
  const {
    register,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  async function connection(e) {
    e.preventDefault();
    setAdmin({ ...admin, formData });

    const newPassword = formData.password;

    if (formData.confirmPassword !== newPassword) {
      alert("La confirmation du mot de passe n'est pas identique.")
      return;
    }

    axios
      .post(`${apiUrl}/api/admins/resetPassword`, {
        token: passwordToken,
        newPassword
      })
      .then((res) => {
        alert("Mot de passe réinitialisé avec succès !");
        setTimeout(() => {
          document.location.pathname = "/connexion";
        }, 1500)
      })
      .catch((err) => {
        console.error(err);
        alert("Erreur : " + err);
      });
  }

  return (
    <div className="bg-bikeexplogray-dark min-h-full h-screen w-screen flex justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-60 w-auto"
            src={LogoTransp}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-2xl font-extrabold text-white">
            Changer le mot de passe de votre compte Bike Explo Admin.
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={connection}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-lg -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Mot de passe
              </label>
              <input
                id="password"
                {...register("password")}
                type="password"
                className={
                  errors.password
                    ? "appearance-none rounded-none relative block w-full px-3 py-2 border-2 border-red-500 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    : "appearance-none rounded-none relative block w-full px-3 mb-5 py-2 border border-salmon placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                }
                placeholder="Nouveau mot de passe"
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
              {errors.password && (
                <p className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.password?.message}
                </p>
              )}
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Confirmation mot de passe
              </label>
              <input
                id="confirm-password"
                {...register("confirmPassword")}
                type="password"
                className={
                  errors.confirmPassword
                    ? "appearance-none rounded-none relative block w-full px-3 py-2 border-2 border-red-500 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    : "appearance-none rounded-none relative block w-full mt-5 px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                }
                placeholder="Confirmation du mot de passe"
                onChange={(e) =>
                  setFormData({ ...formData, confirmPassword: e.target.value })
                }
              />
              {errors.confirmPassword && (
                <p className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.confirmPassword?.message}
                </p>
              )}
            </div>
          </div>

          <div>
            <button
              type="submit"
              onClick={connection}
              disabled={isSubmitting || !isValid}
              className="group relative w-full flex justify-center py-2 px-4 bg-salmon text-sm font-medium rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-salmon"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              Changer de mot de passe
            </button>
            {!isValid && (
              <p className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                Formulaire non valide, veuillez remplir tous les champs !
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
