import Router from "./router/router.js";
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <QueryClientProvider client={queryClient}>
          <Router />
        </QueryClientProvider>
      </header>
    </div>
  );
}

export default App;
