import React, { useState } from "react";
import Axios from "axios";

// IMPORT ICONS
import { CheckOutlined } from "@ant-design/icons";

const API_KEY =
  "pk.eyJ1IjoicGFyY291cnMtbWFwczEiLCJhIjoiY2x2YjBuM2dtMDNhNzJqcDc2ZWMwbXd0ayJ9.dgYngPnMcgiHigv4sexwXQ";

const LocalisationBox = ({ setLat, setLng }) => {
  // Setting up the state variable to store user input
  const [city, setCity] = useState("Marseille");

  // Function to call the API and set the
  // coordinates accordingly
  function getCoordinates() {
    Axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${city}.json?access_token=${API_KEY}`
    ).then((res) => {
      // Longitude
      setLng(res.data.features[0].geometry.coordinates[0]);

      // Latitude
      setLat(res.data.features[0].geometry.coordinates[1]);
    });
  }

  return (
    <div className="flex flex-col lg:flex-row gap-2 justify-center items-center p-1">
      <div className="text-white text-xs sm:text-base text-center">
        <h3>Entrer une destination :</h3>
      </div>
      <div className="flex flex-row gap-1 items-center">
        <input
          id="loc-box"
          className="rounded-full p-2 border-none text-bikeexplogray-light w-20 md:w-36 text-xs sm:text-base"
          type="text"
          onChange={(e) => {
            setCity(e.target.value);
          }}
        />
        <div
          id="loc-btn"
          className="rounded-full p-3 bg-white flex items-center justify-center w-5 h-5 lg:w-7 lg:h-7 text-salmon font-bold text-xs lg:text-xl border-2 border-salmon hover:border-white hover:bg-salmon hover:text-white"
        >
          <CheckOutlined onClick={() => getCoordinates()} />
        </div>
      </div>
    </div>
  );
};

export default LocalisationBox;
