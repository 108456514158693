import React, { useState } from "react";
import axios from "axios";
import { apiUrl } from "../utils/urls.js";
// REACT QUERY
import { useMutation, useQueryClient } from "react-query";

export default function UploadPoi(props) {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  // UPLOAD FILE
  const queryKey = ["blog"];
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    async () => {
      let url = `${apiUrl}/api/upload/uploadarticle`;

      setShowModal(false);
      const form = new FormData();
      const articleId = props.articleId;

      form.append("file", selectedFile, selectedFile.name);
      form.append("articleId", articleId);
      await axios({
        method: "post",
        url: url,
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          console.log("RESPONSE : " + response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  // file upload is complete
  const fileData = () => {
    if (selectedFile) {
      return (
        <div className="text-gray-700 font-playfair">{selectedFile.name}</div>
      );
    } else {
      return (
        <div>
          <br />
          <h4 className="text-salmon flex justify-center">
            Choisis ta photo avant d'envoyer
          </h4>
        </div>
      );
    }
  };

  return (
    <>
      <button
        className="text-white rounded-full p-1 bg-salmon border-2 border-salmon hover:bg-white hover:text-salmon text-xs md:text-base"
        type="button"
        onClick={() => setShowModal(true)}
      >
        + Ajouter une image
      </button>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-bikeexplogray-dark bg-opacity-75">
            <div className="flex flex-col gap-2 bg-bikeexplogray-light rounded-lg p-4">
              <div
                className="flex justify-end"
                onClick={() => setShowModal(false)}
              >
                <svg
                  className="w-6 h-6 text-salmon hover:text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
              </div>
              <div className="flex flex-col justify-center items-center gap-2">
                <input
                  className="text-red-500"
                  type="file"
                  id="file"
                  onChange={onFileChange}
                />
                {fileData()}
                <button
                  onClick={() => mutate()}
                  className=" w-1/2 flex justify-center item-center rounded-full bg-salmon p-1 text-white border-2 border-salmon hover:bg-white hover:text-salmon"
                >
                  Ajouter l'image
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
