import React from "react";
import { useQuery } from "react-query";
import { RefreshIcon } from "@heroicons/react/solid";
import axios from "axios";
import { apiUrl } from "../../utils/urls.js";

//COMPONENTS
import UpdateProduct from "./updateProduct";
import DeleteProduct from "./deleteProduct";

export default function Product() {
  async function getProduct() {
    const { data } = await axios
      .get(`${apiUrl}/api/product`)
      .catch(function (error) {
        console.log(error);
        throw new Error(
          `Une erreur est survenue lors de la récupération de la Product`
        );
      });
    return data;
  }

  const useProduct = () => useQuery("Product", getProduct);
  const { data: Product, isSuccess, isLoading, isError, error } = useProduct();

  return (
    <div className=" flex flex-col gap-2">
      <h1 className="text-salmon font-bold text-base">Products</h1>
      {isLoading && (
        <RefreshIcon className="w-10 h-10 animate-spin mx-auto text-salmon"></RefreshIcon>
      )}
      {isError && (
        <span className="text-red">
          {error.message ? error.message : error}
        </span>
      )}
      {!isLoading && !isError && Product && !Product.length && (
        <span className="text-red-400">Il n'y a pas de produit</span>
      )}
      <div className="flex flex-row gap-2">
        {Product &&
          Product.length > 0 &&
          Product.map((Product, index) => (
            <div
              key={Product.id}
              className="bg-bikeexplogray-light rounded-lg w-1/3"
            >
              <div className="flex flex-row justify-end gap-1 p-1">
                <UpdateProduct id={Product.id} />
                <DeleteProduct id={Product.id} />
              </div>
              <div className="p-2">
                <h2 className="text-salmon font-medium">{Product.name}</h2>
                <p className="text-white">{Product.desc}</p>
                <p className="text-white">{Product.price}€</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
