import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SideBarAdmin from "../components/sidenavbar";
import axios from "axios";
import UploadArticle from "../components/uploadArticle";
// REACT QUERY
import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiUrl } from "../utils/urls.js";

export default function ArticleAdmin({ data }) {
  const [formData, setFormData] = useState({});
  const [article, setArticle] = useState();
  const [confirm, setConfirm] = useState({});
  const [error, setError] = useState("");
  const [popup, setPopup] = useState(false);

  //scrolling menu select categories
  const [allCategoriesOneArticle, getCategoriesOneArticle] = useState([]);

  //scrolling menu select tags
  const [allTagsArticles, getTagsArticles] = useState([]);

  let { id } = useParams();

  function getSelectedValue() {
    // GET SELECT VALUE
    if (document.getElementById("catartdetail") != null) {
      let selectvalue = document.getElementById("catartdetail").value;

      // GET ID ON SELECT VALUE
      let selectid = selectvalue.split(" ");
      return selectid[0];
    }
  }

  function getSelectedTag() {
    // GET SELECT VALUE
    if (document.getElementById("tag") != null) {
      let selecttag = document.getElementById("tag").value;

      // GET ID ON SELECT VALUE
      let selecttagid = selecttag.split(" ");
      return selecttagid[0];
    }
  }

  //associate tag to article
  function addTagsToArticle() {
    const ID = id;
    let selectedTag = getSelectedTag();

    var data = JSON.stringify({
      articles_id: ID,
      tags_id: selectedTag,
    });
    var config = {
      method: "post",
      url: `${apiUrl}/api/tag_articles`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //associate category to article
  async function addCategoryToArticle() {
    let selectedValue = getSelectedValue();
    const ID = id;

    var data = JSON.stringify({
      articles_id: ID,
      categoriesArticle_id: selectedValue,
    });
    var config = {
      method: "post",
      url: `${apiUrl}/api/category_articles`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //delete category from trip
  async function deleteCat(e, catid) {
    axios
      .delete(`${apiUrl}/api/category_articles/cat/${catid}/${id}`)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
    window.location.reload();
  }

  //Update
  async function updateArticle(e) {
    e.preventDefault();
    setArticle({ ...article, formData });

    data = formData;

    axios
      .put(`${apiUrl}/api/articles/${id}`, data)
      .then(function (response) {
        setArticle(response.data);

        //confirmation message
        const mess = "Votre article a bien été modifié";

        setConfirm({ ...confirm, mess });

        setTimeout(function () {
          // after 2 seconds
          document.getElementById("confirmmessage").style.visibility = "hidden";
        }, 2000);

        setTimeout(function () {
          // after 2 seconds
          document.location.pathname = "/allarticles";
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
        setError("Une erreur s'est produite, veuillez rééssayer");
      });
  }

  //handle checkbox
  async function handleCheckBoxPublished() {
    if (document.getElementById("published").checked === true) {
      await setFormData({ ...formData, published: false });
    } else await setFormData({ ...formData, published: true });
  }
  //delete
  async function deleteArticle(e) {
    e.preventDefault();

    axios
      .delete(`${apiUrl}/api/articles/${id}`, data)
      .then(function (response) {
        document.location.pathname = "/allarticles";
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //const [blog, getBlog] = useState("");

  const queryKey = ["blog"];
  const getBlog = async () => {
    const { data } = await axios.get(`${apiUrl}/api/articles/${id}`);
    return data;
  };

  const useBlog = () => useQuery(queryKey, getBlog);
  const {
    data: blog,
    isSuccess: blogIsSuccess,
    isLoading: blogIsLoading,
    isError: blogIsError,
  } = useBlog();

  // DELETE PICTURE
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    async (pictureid) => {
      let url = `${apiUrl}/api/pictures/${pictureid}`;
      await axios
        .delete(url)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  useEffect(() => {
    /*   var config = {
      method: "get",
      url: "https://api.bikeexplo.com/api/articles/" + id,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        getBlog(response.data);
      })
      .catch(function (error) {
        console.log(error);
      }); */

    var config2 = {
      method: "get",
      url: `${apiUrl}/api/categoryarticles`,
      headers: {},
    };

    axios(config2)
      .then(function (response) {
        getCategoriesOneArticle(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${apiUrl}/api/tagsarticle`)
      .then(function (response) {
        getTagsArticles(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });

    //Default values in input and textarea
    document.getElementById("content").defaultValue = blog?.content;
    document.getElementById("titre").defaultValue = blog?.title;
  
  }, [id, blog]);

  return (
    <div className="flex flex-row gap-2 lg:gap-4 bg-bikeexplogray-dark w-screen h-screen">
      <SideBarAdmin />
      <div className="flex flex-col gap-2 lg:gap-4 m-2 sm:m-4 w-full h-screen overflow-y-auto">
        <h1 className="text-white font-bold rounded-lg bg-bikeexplogray-light p-2 flex flex-row gap-1 sm:gap-2 h-10 lg:h-12 items-center text-xs md:text-base">
          <svg
            className="w-5 h-5 lg:w-6 lg:h-6 "
            fill="none"
            stroke="salmon"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          Article Explo Mag{" "}
        </h1>
        <div
          id="confirmmessage"
          class="rounded-full bg-green-400 text-white text-center w-full text-xs md:text-base"
        >
          {" "}
          {confirm.mess}{" "}
        </div>
        <div className="bg-red-600 rounded-lg text-white text-center w-full">
          {error}
        </div>
        <div className="flex flex-row gap-5">
          {/* Bloc 1 image, titre, contenu, validation */}
          <div className="flex flex-col w-2/3 sm:w-4/5 gap-3">
            <div className="rounded-lg bg-bikeexplogray-light flex flex-col sm:flex-row p-2 h-12">
              <label className="text-salmon w-1/4 lg:w-1/5 text-xs md:text-base">
                Titre{" "}
              </label>
              <input
                id="titre"
                name="titre"
                className="w-3/4 lg:w-4/5 overflow-auto appearance-none bg-bikeexplogray-light placeholder-gray-200 text-xs md:text-base w-full focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 text-white"
                //placeholder={blog?.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
              />
            </div>
            <div className="rounded-lg bg-bikeexplogray-light flex flex-col sm:flex-row p-2 h-96">
              <label className="text-salmon w-1/4 lg:w-1/5 text-xs md:text-base">
                Contenu
              </label>
              <textarea
                id="content"
                name="content"
                className="overflow-auto self-start w-3/4 lg:w-4/5 h-full inline-block align-top appearance-none bg-bikeexplogray-light border-bikeexplogray-light rounded-lg placeholder-gray-200 text-white text-xs md:text-base w-full focus:outline-none focus:ring-salmon focus:border-salmon focus:z-10 "
                //placeholder={blog?.content}
                onChange={(e) =>
                  setFormData({ ...formData, content: e.target.value })
                }
              />
            </div>
            <div className="rounded-lg bg-bikeexplogray-light flex flex-row p-2 h-12">
              <label className="text-salmon w-1/4 lg:w-1/5 text-xs md:text-base pr-1">
                Image{" "}
              </label>
              {blogIsSuccess && blog.pictures[0] !== undefined && (
                <button
                  className="text-white rounded-full p-1 bg-salmon border-2 border-salmon hover:bg-white hover:text-salmon text-xs md:text-base"
                  type="button"
                  onClick={(e) => {
                    mutate(blog.pictures[0].id);
                    setPopup(true);
                    setTimeout(() => setPopup(false), 2000);
                  }}
                >
                  Supprimer une image
                </button>
              )}
              {isSuccess && popup && (
                <div className="text-white rounded-full p-1 bg-green-500 border-2 text-center border-green-500 ext-xs md:text-base">
                  Image supprimée
                </div>
              )}
              {blogIsSuccess && blog.pictures[0] === undefined && (
                <UploadArticle articleId={id} />
              )}
            </div>
          </div>
          {/* Bloc 2 : Catégories, Tags, brouillon */}
          <div className="flex flex-col w-1/3 lg:w-1/5 gap-3">
            <div>
              <h1 className="bg-salmon text-white rounded-t-lg p-1 text-center text-xs md:text-base">
                Catégories
              </h1>
              <div className="flex flex-col gap-2 bg-bikeexplogray-light rounded-b-lg p-2">
                <div className="self-center flex flex-row gap-2">
                  <select
                    type="text"
                    placeholder="Catégorie"
                    id="catartdetail"
                    onChange={getSelectedValue}
                    className=" appearance-none rounded-lg bg-bikeexplogray-dark text-bikeexplogray-light w-full text-xs md:text-base"
                  >
                    <option className="text-xs md:text-sm">
                      Ajouter une catégorie
                    </option>
                    {allCategoriesOneArticle.map((cat) => {
                      return (
                        <option
                          key={cat.id}
                          value={cat.id}
                          className="text-xs md:text-base"
                        >
                          {cat.id} {cat?.category}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="bg-salmon text-white text-xs md:text-sm rounded-full p-1 border-2 border-salmon hover:bg-white hover:text-salmon"
                    onClick={addCategoryToArticle}
                  >
                    Ok
                  </button>
                </div>
                <div className="self-center flex flex-row gap-2 flex-wrap">
                  {blog?.categories?.map((cat) => {
                    return (
                      <div
                        className="flex flex-row gap-1 rounded-xl bg-bikeexplogray-dark p-2 text-gray-200 text-xs md:text-sm"
                        key={cat.id}
                      >
                        <button
                          className="items-center rounded-full bg-bikeexplogray-light p-1"
                          onClick={(e) => deleteCat(e, cat.id)}
                        >
                          <svg
                            className="lg:w-3 lg:h-3 w-2 h-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </button>
                        {cat.category}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div>
              <h1 className="bg-salmon text-white rounded-t-lg p-1 text-center text-xs md:text-base">
                Tags{" "}
              </h1>
              <div className="flex flex-col gap-2 bg-bikeexplogray-light rounded-b-lg p-2">
                <div className="self-center flex flex-row gap-2">
                  <select
                    type="text"
                    placeholder="Tag"
                    id="tag"
                    onChange={getSelectedTag}
                    className=" appearance-none rounded-lg bg-bikeexplogray-dark text-bikeexplogray-light w-full text-xs md:text-base"
                  >
                    <option className="text-xs md:text-sm">
                      Ajouter un tag
                    </option>

                    {allTagsArticles.map((tagart) => {
                      return (
                        <option
                          key={tagart.id}
                          value={tagart.id}
                          className="text-xs md:text-sm"
                        >
                          {tagart.id} {tagart.label}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="bg-salmon text-white text-xs md:text-sm rounded-full p-1 border-2 border-salmon hover:bg-white hover:text-salmon"
                    onClick={addTagsToArticle}
                  >
                    Ok
                  </button>
                </div>
                <div className="self-center flex flex-row gap-2 flex-wrap">
                  {blog?.tags?.map((tag) => {
                    return (
                      <div
                        className="rounded-xl bg-bikeexplogray-dark p-2 text-gray-200 text-xs md:text-sm"
                        key={tag.id}
                      >
                        {tag.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between gap-2 bg-bikeexplogray-light rounded-lg p-2 text-xs md:text-base">
              <label className="text-white">Brouillon</label>
              <input
                id="published"
                name="published"
                type="checkbox"
                defaultChecked={true}
                className="appearance-none mr-2 rounded-full focus:ring-salmon"
                onClick={handleCheckBoxPublished}
              />
            </div>
          </div>
        </div>{" "}
        <div className="flex flex-row gap-5 self-center my-4">
          <button
            className="bg-salmon rounded-full p-2 text-white border-2 border-salmon hover:text-salmon hover:bg-white text-xs md:text-base"
            onClick={updateArticle}
          >
            Modifier
          </button>
          <button
            className="text-salmon rounded-full p-2 border-2 border-salmon hover:text-salmon hover:bg-white text-xs md:text-base"
            onClick={deleteArticle}
          >
            Supprimer
          </button>
        </div>
      </div>
    </div>
  );
}
