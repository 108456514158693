import React from "react";

export default function sideBarAdmin() {
  async function logout() {
    localStorage.removeItem("token");
    document.location.pathname = "/connexion";
  }
  return (
    <div className="flex flex-col sm:gap-2 sm:pl-4 pt-2 sm:pt-4 bg-bikeexplogray-light w-16 sm:w-28 lg:w-2/12">
      <h1 className="text-salmon p-1 lg:p-2 font-bold text-xs sm:text-base lg:text-xl text-center lg:text-left">
        BikeExplo Admin
      </h1>
      <a href="/">
        <button className="flex flex-row gap-1 text-center lg:text-left w-28 lg:w-52 rounded-l-full p-5 sm:px-7 lg:p-2 text-white focus:text-salmon focus:bg-bikeexplogray-dark">
          <svg
            className="sm:w-6 sm:h-6 w-5 h-5 focus:text-salmon"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
              clipRule="evenodd"
            ></path>
          </svg>
          <h3 className="hidden lg:block">Parcours</h3>
        </button>
      </a>
      <a href="/poi">
        <button className="flex flex-row gap-1 w-28 lg:w-52 rounded-l-full p-5 sm:px-7 lg:p-2 text-white focus:text-salmon focus:bg-bikeexplogray-dark">
          <svg
            className="sm:w-6 sm:h-6 w-5 h-5"
            viewBox="0 0 14 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 2V7C9 8.12 9.37 9.16 10 10H4C4.65 9.14 5 8.1 5 7V2H9ZM12 0H2C1.45 0 1 0.45 1 1C1 1.55 1.45 2 2 2H3V7C3 8.66 1.66 10 0 10V12H5.97V19L6.97 20L7.97 19V12H14V10C12.34 10 11 8.66 11 7V2H12C12.55 2 13 1.55 13 1C13 0.45 12.55 0 12 0Z"
              fill="white"
            />
          </svg>

          <h3 className="hidden lg:block">Points d'intérêt</h3>
        </button>
      </a>
      <a href="/allarticles">
        <button className="flex flex-row gap-1 w-28 lg:w-52 rounded-l-full p-5 sm:px-7 lg:p-2 text-white focus:text-salmon focus:bg-bikeexplogray-dark">
          <svg
            className="sm:w-6 sm:h-6 w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <h3 className="hidden lg:block">Explo Mag</h3>
        </button>
      </a>
      <a href="/categoriesettags">
        <button className="flex flex-row gap-1 w-28 lg:w-52 rounded-l-full p-5 sm:px-7 lg:p-2 text-white focus:text-salmon focus:bg-bikeexplogray-dark">
          <svg
            className="sm:w-6 sm:h-6 w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
            ></path>
          </svg>
          <h3 className="hidden lg:block">Catégories & Tags</h3>
        </button>
      </a>
      <a href="/utilisateurs">
        <button className="flex flex-row gap-1 w-28 lg:w-52 rounded-l-full p-5 sm:px-7 lg:p-2 text-white focus:text-salmon focus:bg-bikeexplogray-dark">
          <svg
            className="sm:w-6 sm:h-6 w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            ></path>
          </svg>
          <h3 className="hidden lg:block">Clients</h3>
        </button>
      </a>
      <a href="/chiffresclés">
        <button className="flex flex-row gap-1 w-28 lg:w-52 rounded-l-full p-5 sm:px-7 lg:p-2 text-white focus:text-salmon focus:bg-bikeexplogray-dark">
          <svg
            className="sm:w-6 sm:h-6 w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
            ></path>
          </svg>
          <h3 className="hidden lg:block">Chiffres clés</h3>
        </button>
      </a>
      <a href="/product">
        <button className="flex flex-row gap-1 w-28 lg:w-52 rounded-l-full p-5 sm:px-7 lg:p-2 text-white focus:text-salmon focus:bg-bikeexplogray-dark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="sm:w-6 sm:h-6 w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
            />
          </svg>
          <h3 className="hidden lg:block">Produits</h3>
        </button>
      </a>
      <a href="/faq">
        <button className="flex flex-row gap-1 w-28 lg:w-52 rounded-l-full p-5 sm:px-7 lg:p-2 text-white focus:text-salmon focus:bg-bikeexplogray-dark">
          <svg
            className="sm:w-6 sm:h-6 w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
            ></path>
          </svg>
          <h3 className="hidden lg:block">F.A.Q.</h3>
        </button>
      </a>
      <a href="/profil">
        <button className="flex flex-row gap-1 w-28 lg:w-52 rounded-l-full p-5 sm:px-7 lg:p-2 text-white focus:text-salmon focus:bg-bikeexplogray-dark">
          <svg
            className="sm:w-6 sm:h-6 w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            ></path>
          </svg>
          <h3 className="hidden lg:block">Profil</h3>
        </button>
      </a>
      <button
        className="flex flex-row gap-1 w-28 lg:w-52 text-salmon p-5 sm:px-7 lg:p-2 mt-10"
        onClick={logout}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 3H11V13H13V3ZM17.83 5.17L16.41 6.59C17.99 7.86 19 9.81 19 12C19 15.87 15.87 19 12 19C8.13 19 5 15.87 5 12C5 9.81 6.01 7.86 7.58 6.58L6.17 5.17C4.23 6.82 3 9.26 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 9.26 19.77 6.82 17.83 5.17Z"
            fill="salmon"
          />
        </svg>

        <h3 className="hidden lg:block">Déconnexion</h3>
      </button>
      <div className="fixed bottom-0 mb-2">
        <a
          href="http://www.bikeexplo.com"
          className="text-xs text-salmon underline-offset-1 hover:text-white hover:z-10 hidden lg:block"
        >
          Mentions Légales
        </a>
        <svg
          className="w-4 h-4 block lg:hidden text-salmon mx-6 md:mx-7"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      </div>
    </div>
  );
}
