import React from "react";

export default function AddClientButton(props) {
  console.log(props.id);
  function click() {
    document.location.pathname = `/addclient/${props.id}`;
  }
  return (
    <button
      className="border-2 border-salmon text-salmon rounded-full hover:bg-white p-1 w-1/2 text-xs sm:text-base"
      onClick={click}
      title="Si le client n'est pas présent dans le menu déroulant"
    >
      Créer
    </button>
  );
}
