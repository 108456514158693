import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl, frontUrl } from "../utils/urls.js";

export default function CategoriesArticles() {
  const [categorie, getCategorie] = useState([]);

  useEffect(() => {
    axios(`${apiUrl}/api/categoryarticles`)
      .then((response) => {
        getCategorie(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="flex flex-row gap-1 w-full overflow-x-auto">
      {categorie.map((cat) => {
        return (
          <div key={cat.id}>
            <a
              key={cat.id}
              href={`${frontUrl}/categoriearticles/${cat.id}`}
            >
              <h2 className="flex h-8/12 whitespace-nowrap rounded-full bg-salmon text-white p-2 text-xs md:text-base hover:bg-hoversalmon">
                {cat.category}
              </h2>
            </a>
          </div>
        );
      })}
    </div>
  );
}
