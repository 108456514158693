import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { apiUrl } from "../utils/urls.js";

export default function UpdateProfil({ data }) {
  const [showModal, setShowModal] = React.useState(false);
  const [formData, setFormData] = useState({});
  const [admin, setAdmin] = useState(data);
  const [confirm, setConfirm] = useState({});

  async function updateAdmin(e) {
    e.preventDefault();
    setAdmin({ ...admin, formData });

    var tok = localStorage.getItem("token");
    var decoded = jwt_decode(tok);
    var Id = decoded.id;

    var data = formData;
    var config = {
      method: "put",
      url: `${apiUrl}/api/admins/${Id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        const mess = "Votre compte a bien été modifié";
        setTimeout(function () {
          // after 1 seconds
          document.location.pathname = "/profil";
        }, 1000);
        setConfirm({ ...confirm, mess });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //Show/Hide password
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    setConfirm({ ...confirm, formData });
    const password = formData.password;
    const confirmpassword = formData.confirmpassword;

    if (password === undefined || confirmpassword === undefined) {
      const message = "";
      setConfirm({ ...confirm, message });
      return message;
    } else if (password === confirmpassword) {
      const message = "";
      setConfirm({ ...confirm, message });
      return message;
    } else if (password !== confirmpassword) {
      const message = "Les mots de passe ne correspondent pas";
      setConfirm({ ...confirm, message });
      return message;
    }
  }, [formData, confirm]);

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        Modifier
      </button>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-bikeexplogray-dark bg-opacity-75 overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div class="lg:w-5/12 lg:h-3/6 md:h-80 bg-bikeexplogray-light rounded-lg shadow-lg p-1 sm:p-3">
              <div
                class="flex justify-end mr-5 mt-3"
                onClick={() => setShowModal(false)}
              >
                <svg
                  className="w-5 h-5 lg:w-6 lg:h-6 text-salmon hover:text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
              </div>
              <h1 className="rounded-lg bg-bikeexplogray-light text-white font-bold p-2 px-3">
                {" "}
                Modifier mon profil{" "}
              </h1>
              <div
                className="mt-8"
                //enctype="multipart/form-data"
              >
                <p class="mx-12 bg-red-600 rounded-lg text-white text-xs text-base">
                  {" "}
                  {confirm.message}
                </p>
                <p class=" mx-12 my-2 rounded-lg text-white bg-green-500 text-xs text-base">
                  {" "}
                  {confirm.mess}
                </p>
                <input type="hidden" name="remember" value="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="username" className="sr-only">
                      Nom d'utilisateur
                    </label>
                    <input
                      id="username"
                      name="username"
                      className="w-11/12 appearance-none rounded-none px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 rounded-t-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Nom d'utilisateur"
                      onChange={(e) =>
                        setFormData({ ...formData, username: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Email
                    </label>
                    <input
                      id="email-address"
                      name="email"
                      className="w-11/12 appearance-none rounded-none px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs lg:text-base"
                      placeholder="Email"
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="relative">
                    <label htmlFor="password" className="sr-only">
                      Mot de passe
                    </label>
                    <input
                      id="password"
                      name="password"
                      type={passwordShown ? "text" : "password"}
                      className="w-11/12 appearance-none  rounded-none px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs lg:text-base"
                      placeholder="Mot de passe (une majuscule, un chiffre et un caractère spécial)"
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                    />
                    <button
                      className="absolute inset-y-0 right-8 pl-72 pt-2 text-salmon hover:text-bikeexplogray-light"
                      onClick={togglePassword}
                    >
                      <svg
                        className="w-4 h-4 lg:w-6 lg:h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        ></path>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Confirmation du mot de passe
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      className="w-11/12 appearance-none rounded-none rounded-b-lg px-3 py-2 border border-salmon placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs lg:text-base"
                      placeholder="Confirmation du mot de passe"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          confirmpassword: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <button
                  className="rounded-full bg-salmon text-white border-2 border-salmon px-2 py-1 mt-6 mb-6 text-center shadow-xl hover:bg-white hover:text-salmon"
                  type="submit"
                  onClick={updateAdmin}
                >
                  Confirmer
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
